<template>
    <div class="pageContainer">
        <a-row class="financialManagement">
            <a-breadcrumb>
                <a-breadcrumb-item>法律咨询</a-breadcrumb-item>
            </a-breadcrumb>
            <a-form-model layout="inline" ref="ruleForm" :model="formInline" @submit="handleSubmit" @submit.native.prevent class="choose">
                <a-form-model-item prop="name">
                    <a-input placeholder="请输入名称" v-model="formInline.name" />
                </a-form-model-item>
                <a-form-model-item class="search">
                    <a-dropdown-button type="primary" html-type="submit">
                        <a-icon type="search" />查询
                        <a-menu slot="overlay">
                            <a-menu-item key="1" @click="reset">重置查询选项</a-menu-item>
                        </a-menu>
                    </a-dropdown-button>
                </a-form-model-item>
            </a-form-model>
            <div class="list">
                <a-row v-for="item in listData" :key="item.id" class="talentList">
                    <a-col :span="2">
                        <img :src="baseImgUrl + item.photoUrl" width="80px" height="80px" />
                    </a-col>
                    <a-col :span="18">
                        <a-row style="marginTop:12px">
                            <a>{{ item.name }}</a
                            >&nbsp;&nbsp;
                            <span>{{ item.level == 1 ? "初级" : item.level == 2 ? "中级" : item.level == 3 ? "高级" : "" }}</span>
                            <span>{{ item.type == 1 ? "人才" : item.type == 2 ? "专家" : item.type == 3 ? "律师" : "" }}</span>
                        </a-row>
                        <a-row style="marginTop:12px">
                            <span>擅长:</span>
                            <span>{{ item.areasExpertise }}</span>
                        </a-row>
                        <!-- <a-row style="marginTop:12px">
                            <span>{{ item.appointmentsSum }}人已联系</span>
                        </a-row> -->
                    </a-col>
                    <a-col :span="4">
                        <a-button type="primary" class="detailsBtn" @click="getDetails(item.flowId)">
                            了解详情
                        </a-button>
                    </a-col>
                </a-row>
            </div>
            <a-pagination v-model="current" :page-size-options="pageSizeOptions" :total="total" show-size-changer :page-size="pageSize" @showSizeChange="onShowSizeChange" style="float:right;marginTop:15px" @change="onChange">
                <template slot="buildOptionText" slot-scope="props">
                    <span v-if="props.value !== '50'">{{ props.value }}条/页</span>
                    <span v-if="props.value === '50'">全部</span>
                </template>
            </a-pagination>
        </a-row>
    </div>
</template>
<script>
export default {
    data() {
        return {
            formInline: {
                type: undefined,
                name: "",
            },
            paymentStatus: "",
            listData: [],
            baseImgUrl: process.env.VUE_APP_RESOURCES_HOST,
            pageSizeOptions: ["5", "10", "15", "20"],
            current: 1,
            pageSize: 10,
            total: 0,
            userInfo: {},
        };
    },
    created() {
        this.userInfo = this.$store.getters.userInfo;
        this.handleSubmit("placeholder", 0, 10);

        if (document.body.clientWidth <= 1366) {
            this.tableHeight = 390;
        } else if (document.body.clientWidth > 1366 && document.body.clientWidth <= 1440) {
            this.tableHeight = 500;
        } else if (document.body.clientWidth > 1440 && document.body.clientWidth <= 1600) {
            this.tableHeight = 530;
        } else if (document.body.clientWidth > 1600) {
            this.tableHeight = 650;
        }
    },
    methods: {
        getDetails(flowId) {
            this.$router.push({
                name: "Lawyer",
                query: { flowId: flowId },
            });
        },
        handleSubmit(e, pageNum, pageSize) {
            let that = this;
            let params = {
                pageNum: pageNum || pageNum == 0 ? pageNum : 0,
                pageSize: pageSize ? pageSize : that.pageSize ? that.pageSize : 10,
                type: [3],
                province: this.userInfo.province || "",
            };
            if (this.formInline.name) {
                params.name = this.formInline.name;
            }
            this.$http
                .post("/resource/usertalent/queryPageList", params)
                .then((res) => {
                    if (res.rescode == 200) {
                        this.listData = res.data.dataList;
                        this.total = res.data.totalCount;
                    } else {
                        this.$message.warning(res.msg);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        onShowSizeChange(current, pageSize) {
            this.pageSize = pageSize;
        },
        onChange(current) {
            this.current = current;
            this.handleSubmit("placeholder", current - 1, this.pageSize);
        },
        reset() {
            this.$refs.ruleForm.resetFields();
            this.handleSubmit("placeholder", 0, this.pageSize);
            this.current = 0;
        },
    },
};
</script>
<style lang="less" scoped>
.pageContainer {
    .financialManagement {
        padding: 16px;
        height: 100%;
        background: #fff;
        .choose {
            margin-top: 10px;
            // padding-bottom: 20px;
            // border-bottom: 1px solid gray;
        }
        .button {
            margin-top: 3px;
            margin-right: 20px;
            color: #999999;
        }
        .details {
            float: right;
        }
        .list {
            height: 600px;
            overflow: auto;
            margin-top: 30px;

            .talentList {
                margin: 20px 30px;
                height: 120px;
                border-bottom: 1px solid #eee;
            }
        }
    }
}
</style>
