<template>
    <div>
        <a-modal :width="700" :maskClosable="false" :visible="addMedium" :title="opMedium == 'add' ? '创建媒介信息' : '修改媒介信息'" :confirmLoading="spinning" @ok="submit" @cancel="closeModal">
            <a-spin :spinning="spinning">
                <a-form-model ref="mediumForm" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules">
                    <a-form-model-item label="媒介名称" prop="name" :whitespace="true">
                        <a-input v-model="form.name" placeholder="请输入媒介名称" :maxLength="20"/>
                    </a-form-model-item>
                    <a-form-model-item label="媒介类型" prop="type">
                        <a-select v-model="form.type" placeholder="请选择媒介类型">
                            <a-select-option :key="index" :value="item.value" v-for="(item, index) in mediumTypeList">{{ item.value }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="媒介长" prop="height">
                        <a-input-number class="numInput" v-model="form.height" placeholder="请输入媒介长" :min="1"></a-input-number>
                    </a-form-model-item>
                    <a-form-model-item label="媒介宽" prop="width">
                        <a-input-number class="numInput" v-model="form.width" placeholder="请输入媒介宽" :min="1"></a-input-number>
                    </a-form-model-item>
                    <a-form-model-item label="媒介区域" prop="regionStr" layout="inline">
                        <a-input-group compact>
                            <a-input class="regionInput" :disabled="true" v-model="form.regionStr" placeholder="请打开地图选择区域"></a-input>
                            <a-button class="mapBtn" type="primary" @click="mapBtnClick">地图选址</a-button>
                        </a-input-group>
                    </a-form-model-item>
                    <a-form-model-item label="详细地址" prop="address">
                        <a-input-group compact>
                            <a-input :disabled="true" v-model="form.address" placeholder="请打开地图选择区域"></a-input>
                        </a-input-group>
                    </a-form-model-item>
                    <a-form-model-item label="刊例价格" prop="price">
                        <a-input-number class="numInput" v-model="form.price" placeholder="请输入刊例价格" :min="0"></a-input-number>
                    </a-form-model-item>
                    <a-form-model-item label="刊例类型" prop="dateType">
                    <a-select v-model="form.dateType" placeholder="请选择刊例时间">
                        <a-select-option :value="0">
                            日
                        </a-select-option>
                        <a-select-option :value="1">
                            周
                        </a-select-option>
                        <a-select-option :value="2">
                            月
                        </a-select-option>
                        <a-select-option :value="3">
                            年
                        </a-select-option>
                    </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="媒介照片" prop="imagesUrl">
                        <a-upload :action="baseUrl" list-type="picture-card" :before-upload="beforeImgUpload" :file-list="fileList" @preview="handlePreview" @change="handlechange">
                            <div v-if="fileList.length < 4">
                                <a-icon type="plus" />
                                <div class="ant-upload-text">
                                    上传图片
                                </div>
                            </div>
                        </a-upload>
                    </a-form-model-item>
                    <a-form-model-item label="备注" prop="remark">
                        <a-textarea :auto-size="{ minRows: 5, maxRows: 8 }" v-model="form.remark" placeholder="请输入备注" :maxLength="300" />
                    </a-form-model-item>
                </a-form-model>
            </a-spin>
        </a-modal>
        <MapModal v-if="mapVisible" :mapVisible="mapVisible" :closeMapModal="closeMapModal" />
        <Vviewer ref="viewer"></Vviewer>
    </div>
</template>
<script>
import MapModal from "./MapModal.vue";
import options from "../../../utils/common/cascader-address-options";
import Vviewer from "../../../components/Vviewer";

options.map((provinceItem) => {
    provinceItem.value = provinceItem.label;
    provinceItem.children.map((cityItem) => {
        cityItem.value = cityItem.label;
        cityItem.children.map((districtItem) => {
            districtItem.value = districtItem.label;
        });
    });
});

export default {
    props: ["addMedium", "closeAddMedium", "opMedium"],
    components: {
         MapModal,
         Vviewer,
        },
    data() {
        const _this = this;
        let chekckPic = (rule, value, callback) => {
            if (_this.fileList.length == 0) {
                callback(new Error("请输入LOGO图片"));
            } else {
                callback();
            }
        };
        return {
            mapVisible: false,
            spinning: false,
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
            fileList: [],
            baseUrl: process.env.VUE_APP_RESOURCES_API_HOST,
            baseImgUrl: process.env.VUE_APP_RESOURCES_HOST,
            options: options,
            position: [],
            form: {
                name: "",
                type: undefined,
                height: "",
                width: "",
                size: "",
                region: [],
                remark: "",
                imagesUrl: "",
                address: "",
                regionStr: '',
                price:"",
                dateType:"",
            },
            rules: {
                name: [{ required: true, trigger: "change", message: "请输入媒介名称" }],
                type: [{ required: true, trigger: "change", message: "请输入媒介种类" }],
                height: [{ required: true, trigger: "change", message: "请输入数字" }],
                width: [{ required: true, trigger: "change", message: "请输入数字" }],
                address: [{ required: true, trigger: "change", message: "请打开地图选择区域" }],
                regionStr: [{ required: true, trigger: "change", message: "请打开地图选择区域" }],
                imagesUrl: [{ required: true, trigger: "blur", validator: chekckPic, message: "请上传媒介图片" }],
                price:[{required: true, trigger: "change", message: "请输入刊例价格"}],
                dateType:[{required: true, trigger: "change",message:"请选择刊例类型"}],
            },
            previewVisible: false,
            //address: [],
            mediumTypeList: [],
            dateTypeList:[],
        };
    },
    //修改框内查看
    watch: {
        opMedium(data) {
            if (data != "add") {
                this.spinning = true;
                const _this = this;
                this.$http.get("/publishmedia/query?id=" + data).then((res) => {
                    _this.spinning = false;
                    _this.form = {
                        name: res.data.name,
                        type: res.data.type,
                        height: res.data.mediaLength,
                        width: res.data.mediaWidth,
                        size: res.data.size,
                        regionStr: res.data.province + res.data.city + res.data.district,
                        region: [res.data.province ? res.data.province : "", res.data.city ? res.data.city : "", res.data.district ? res.data.district : ""],
                        remark: res.data.remark,
                        address: res.data.address,
                        longitude:res.data.longitude,
                        latitude:res.data.latitude,
                        dateType:res.data.dateType,
                        price:res.data.price,
                    };
                    res.data.imagesUrl.map((item, index) => {
                        let obj = {
                            uid: index,
                            name: "image" + index + '.' + item.split('.')[1],
                            status: "done",
                            url: this.baseImgUrl + item,
                            imagesUrl: item,
                        }
                        _this.fileList.push(obj)
                    })
                });
            }
        },
        addMedium(data) {
            if (data) {
                this.getMediumTypeList();
            }
        },
    },
    methods: {
        //查看大图
        async handlePreview(file) {
                if(file.response && file.response != ""){
                    this.$refs.viewer.show([
                    {
                      thumbnail:process.env.VUE_APP_RESOURCES_HOST+file.response.data.filePath,
                      source:process.env.VUE_APP_RESOURCES_HOST+file.response.data.filePath,
                    },
                ]);
                }else{
                  this.$refs.viewer.show([
                    {
                      thumbnail:process.env.VUE_APP_RESOURCES_HOST+file.imagesUrl,
                      source:process.env.VUE_APP_RESOURCES_HOST+file.imagesUrl,
                    },
                ]);
            }
        },
        closeMapModal(data) {
            this.mapVisible = false;
            if (data) {
                this.form.regionStr = data.addressComponent.province + data.addressComponent.city + data.addressComponent.district;
                this.form.region = [data.addressComponent.province, data.addressComponent.city, data.addressComponent.district];
                this.form.address = data.formattedAddress;
                this.position = data.position;
            }
        },
        mapBtnClick() {
            this.mapVisible = true;
        },
        getMediumTypeList() {
            let params = {};
            const _this = this;
            _this.$http
                .post("/publishmediatype/queryList", params)
                .then((res) => {
                    if (res.rescode == 200) {
                        _this.mediumTypeList = res.data.dataList;
                    }
                })
                .catch((err) => {
                    _this.spinning = false;
                });
        },
        //上传图片判定条件
        beforeImgUpload(file) {
            return new Promise((resolve, reject) => {
                var testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
                const isLt2M = file.size / 1024 / 1024 <= 2; //图片大小不超过2MB
                if (!testmsg) {
                    this.$message.error("只支持上传图片");
                    return reject(false);
                }
                if (!isLt2M) {
                    this.$message.error("上传图片大小不能超过2MB!");
                    return reject(false);
                }
                return resolve(true);
            });
        },
        //关闭预览
        handleCancel() {
            this.previewVisible = false;
        },
        //上传图片
        async handlechange({ file, fileList }) {
            if (file.status == "done" && file.response.rescode == 200) {
                fileList.map((item) => {
                    if (item.status == "done") {
                        if(item.response && item.response != ""){
                            if (item.response.data.coverImgUrl && item.response.data.coverImgUrl != "") {
                            item.thumbUrl = process.env.VUE_APP_RESOURCES_HOST + item.response.data.coverImgUrl;
                        }
                        }
                        
                    }
                });
            } else if (file.status == "done" && file.response.rescode == 201) {
                this.$message.warning(file.response.msg);
                fileList = fileList.filter((item) => {
                    return item.uid != file.uid;
                });
            }
            this.fileList = fileList;
        },

        submit() {
            const _this = this;
            this.$refs.mediumForm.validate((valid) => {
                if (valid) {
                    _this.spinning = true;
                     let imgList = []
                        this.fileList.map(item => {
                            if (item.response){
                                imgList.push(item.response.data.filePath)
                            } else {
                                imgList.push(item.imagesUrl)
                            }
                        })
                    if (_this.opMedium == "add") {
                        let params = {
                            name: _this.form.name,
                            type: _this.form.type,
                            size: _this.form.height + "x" + _this.form.width,
                            province: _this.form.region[0],
                            city: _this.form.region[1],
                            district: _this.form.region[2],
                            address: _this.form.address,
                            remark: _this.form.remark,
                            longitude: _this.position[0],
                            latitude: _this.position[1],
                            imagesUrl: imgList.join(';'),
                            price:_this.form.price * 1000,
                            dateType:_this.form.dateType,
                        };
                        _this.$http
                            .post("/publishmedia/add", params)
                            .then((res) => {
                                _this.$message.success("操作成功");
                                _this.fileList = [];
                                _this.regionStr = "";
                                _this.closeModal();
                            })
                            .catch((err) => {
                                _this.spinning = false;
                            });
                    } else {
                        let params = {
                            id: _this.opMedium,
                            name: _this.form.name,
                            type: _this.form.type,
                            size: _this.form.height + "x" + _this.form.width,
                            province: _this.form.region[0],
                            city: _this.form.region[1],
                            district: _this.form.region[2],
                            address: _this.form.address,
                            remark: _this.form.remark,
                            longitude: _this.form.longitude,
                            latitude: _this.form.latitude,
                            imagesUrl: imgList.join(';'),
                            price:_this.form.price * 1000,
                            dateType:_this.form.dateType,
                        };
                        _this.$http
                            .post("/publishmedia/update", params)
                            .then((res) => {
                                if(res.rescode == 200){
                                    _this.$message.success("操作成功");
                                    _this.closeModal();
                                }else{
                                    _this.spinning = false;
                                    _this.$message.error(res.msg);
                                }
                            })
                            .catch((err) => {
                                _this.spanning = false;    
                                _this.$message.error(res.msg);
                            });
                    }
                }
            });
        },
        closeModal() {
            this.spinning = false;
            this.$refs.mediumForm.resetFields();
            this.fileList = [];
            this.closeAddMedium();
        },
        onChange(e) {
            console.log(e);
        },
    },
};
</script>
<style lang="less" scoped>
.regionInput {
    width: 65%;
}
.mapBtn {
    width: 35%;
}
.numInput {
    width: 100%;
}
</style>
