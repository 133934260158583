<template>
    <a-modal :visible="meetVisible" @cancel="meetCloseModal" :footer="null" :data="infoData" v-if="infoData">
        <a-row>
            <a-col :span="5" :offset="4">
                <img :src="baseImgUrl + infoData.photoUrl" width="85px" height="100px" />
            </a-col>
            <a-col :span="14">
                <a-row>
                    <span>{{ infoData.name }}</span
                    >&nbsp;&nbsp;&nbsp;
                    <span>{{ infoData.level == 1 ? "初级" : infoData.level == 2 ? "中级" : infoData.level == 3 ? "高级" : "" }}</span>
                    <span>{{ infoData.type == 1 ? "人才" : infoData.type == 2 ? "专家" : infoData.type == 3 ? "律师" : "" }}</span>
                </a-row>
                <a-row>
                    <span>擅长：</span>&nbsp;&nbsp;
                    <span>{{ infoData.areasExpertise }}</span>
                </a-row>
            </a-col>
        </a-row>
        <a-divider />
        <a-row>
            <a-col :span="4" :offset="4">
                <span>服务类型</span>
                <a-radio-group v-model="value" @change="onChange">
                    <a-radio v-for="(item, index) in priceList" :style="radioStyle" :value="index" :key="item.flowId"> ￥{{ item.price / 1000 }}/{{ item.unit }}小时 </a-radio>
                </a-radio-group>
                {{ priceList.length > 0 ? "" : "暂无活动" }}
            </a-col>
        </a-row>
        <a-divider />
        <a-row>
            <a-col :span="18" :offset="4">
                <span>预约事项：</span>
                <p>1、请注意接听客服回访来电</p>
                <p>2、客服时间为早上9：00到晚上6：00，非工作时间请耐心等待。</p>
                <i>温馨提示：为提高沟通效率，请在沟通之前提前整理好业务需求。</i>
            </a-col>
        </a-row>
        <a-divider />

        <a-row>
            <a-col :span="10" :offset="8">
                <span>总额：</span>
                <span>￥</span>
                <b class="money">{{ money / 1000 }}</b>
            </a-col>
            <a-col :span="4">
                <a-button type="primary" block @click="getPay">
                    去支付
                </a-button>
            </a-col>
        </a-row>
        <PayModal :payData="payData" :payModalVisible="payModalVisible" :tradeId="tradeId" :closePayModal="closePayModal" :changeTradeId="changeTradeId" />
    </a-modal>
</template>
<script>
import PayModal from "./PayModal.vue";
export default {
    props: ["meetVisible", "meetCloseModal", "infoData", "type"],
    components: {
        PayModal,
    },
    data() {
        return {
            value: 0,
            radioStyle: {
                display: "block",
                height: "30px",
                lineHeight: "30px",
            },
            baseImgUrl: process.env.VUE_APP_RESOURCES_HOST,
            money: "",
            payModalVisible: false,
            tradeId: null, // 主订单号
            payData: {},
            priceList: [],
        };
    },
    watch: {
        meetVisible(data) {
            if (data) {
                if (this.infoData.length != 0) {
                    if (this.type == 2) {
                        this.priceList = this.infoData.userTalentCooperationVoList.filter((item) => {
                            return item.cooperationMode == 1;
                        });
                    } else {
                        this.priceList = this.infoData.userTalentCooperationVoList.filter((item) => {
                            return item.cooperationMode == 2;
                        });
                    }
                    this.money = this.priceList[0].price;
                }
            }
        },
    },
    methods: {
        changeTradeId(data) {
            this.tradeId = data;
        },
        handleCancel() {
            this.meetCloseModal();
        },
        onChange(value) {
            this.money = this.infoData.userTalentCooperationVoList[this.value].price;
        },
        getmeet() {
            let params = {
                type: this.type,
                userTalentId: this.infoData.flowId,
                state: 0,
                amount: this.infoData.userTalentCooperationVoList[this.value].price,
                price: this.infoData.userTalentCooperationVoList[this.value].price,
            };
            this.$http
                .post("resource/trainorder/add" + params)
                .then((res) => {
                    if (res.rescode == 200) {
                        // console.log(res);
                        this.commentsList = res.data;
                        // this.data = res.data.orderReviewPoList;
                    } else {
                        this.$message.warning(res.msg);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getPay() {
            const _this = this;
            let params = {
                type: this.type,
                amount: this.infoData.userTalentCooperationVoList[this.value].price,
                price: this.infoData.userTalentCooperationVoList[this.value].price,
                userTalentId: this.infoData.flowId,
            };
            this.$http
                .post("/resource/trainorder/add", params)
                .then((res) => {
                    _this.tradeId = res.data.orderId;
                    _this.payData = params;
                    _this.payModalVisible = true;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        closePayModal() {
            this.payModalVisible = false;
            // this.$router.push({ name: "CheckOrder" });
        },
    },
};
</script>
<style lang="less" scoped>
.ant-row {
    margin: 25px 0;
}
.ant-avatar-image {
    width: 100px;
    height: 120px;
}
.money {
    color: orangered;
}
.ant-btn-primary {
    background: orangered;
    border-color: orangered;
}
a {
    color: orangered;
}
</style>
