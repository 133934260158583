<template>
    <div class="pageContainer">
        <a-row class="IndustryTrainingDetail">
            <a-spin :spinning="pageLoading" :class="!activeInfo ? 'flex justify-center align-center' : ''">
                <template v-if="activeInfo">
                    <a-breadcrumb>
                        <a-breadcrumb-item>
                            <router-link to="/IndustryTraining">培训列表</router-link>
                        </a-breadcrumb-item>
                        <a-breadcrumb-item>
                            培训详情
                        </a-breadcrumb-item>
                    </a-breadcrumb>
                    <a-row class="info" :gutter="16">
                        <a-col :span="6">
                            <div class="imgBox">
                                <img :src="staticBaseUrl + activeInfo.activityUrl" />
                            </div>
                            <div class="share" v-if="token != ''">
                                分享:
                                <a-tooltip title="点击可分享当前页" @click="copy()">
                                    <a-button icon="link" />
                                </a-tooltip>
                            </div>
                        </a-col>
                        <a-col :span="7" class="right">
                            <div class="activeName flex align-center">
                                <div class="tag" v-if="activeInfo.promotionMethods == 1">
                                    <a-tag class="tag" color="#fa541c">精选</a-tag>
                                </div>
                                <div class="name">{{ activeInfo.activityName }}</div>
                            </div>
                            <div class="activeDetail">
                                <div>时间: {{ getTime(activeInfo.activityStartTime, activeInfo.activityEndTime) }}</div>
                                <div>地点: {{ activeInfo.activityAddress }}</div>
                                <div>已报名人数: {{ activeInfo.applicantsSum ? activeInfo.applicantsSum : 0 }}人, 剩余报名人数: {{ activeInfo.remainingPlaces }}人</div>
                                <div>活动费用: {{ activeInfo.activityPrice / 1000 }}元/人</div>
                            </div>
                            <div class="flex justify-between marginb_16">
                                <span>限定人数: {{ activeInfo.activityQuota }}</span>
                                <span>分享人数: {{ activeInfo.shareSum ? activeInfo.shareSum : 0 }}</span>
                                <span>收藏人数: {{ activeInfo.collectionSum ? activeInfo.collectionSum : 0 }}</span>
                            </div>
                            <div  class="flex justify-between marginb_16 discount">
                                <span v-if="activeInfo.activityDiscountTop">前{{ activeInfo.activityDiscountTop }}位报名享优{{ activeInfo.activityDiscount / 10 }}折 </span>
                                <span>{{activeInfo.refundType == 0 ? "不可退款" : activeInfo.refundType == 1 ? "可退款" : "--"}}</span>
                            </div>
                            <div class="btnList flex justify-between">
                                <a-button type="primary" @click="favoritesChange">
                                    <a-icon type="star" :theme="favoritesStatus ? 'filled' : 'outlined'"></a-icon>
                                    收藏
                                </a-button>
                                <a-button type="primary" @click="contact">马上咨询</a-button>
                                <a-button type="primary" @click="addActivityRegistration" :disabled="getActiveState(activeInfo.activityStartTime, activeInfo.activityEndTime) == '立即报名' ? false : true">
                                    {{ getActiveState(activeInfo.activityStartTime, activeInfo.activityEndTime) }}
                                </a-button>
                            </div>
                            <a-tag color="orange" class="prompt" v-if="getActiveState(activeInfo.activityStartTime, activeInfo.activityEndTime) == '报名截止' ? true : false">
                                活动前48小时内截止报名!
                            </a-tag>
                        </a-col>
                    </a-row>

                    <a-row class="infoBox">
                        <div class="title">活动详情</div>
                        <a-col :span="24">
                            <div v-html="activeInfo.activityDetails"></div>
                        </a-col>
                    </a-row>
                </template>
            </a-spin>
            <!-- 用于存放并复制当前链接的input -->
            <input type="text" v-model="text" readonly="readonly" id="inputText" ref="inputText" />
        </a-row>
        <CustomerService :visible="contactVisible" :closeModal="closeModal" :goType="goType" />
        <AddActivityRegistration v-if="activeInfo" :activeInfo="activeInfo" :addActivityRegistrationVisible="addActivityRegistrationVisible" :closeAddActivityRegistrationModal="closeAddActivityRegistrationModal" />
    </div>
</template>
<script>
import CustomerService from "../Home/components/CustomerService.vue";
import AddActivityRegistration from "./components/AddActivityRegistration.vue";
export default {
    data() {
        return {
            activeInfo: null,
            pageLoading: true,
            contactVisible: false,
            goType: "customerService",
            staticBaseUrl: process.env.VUE_APP_RESOURCES_HOST,
            favoritesStatus: false,
            favoritesObj: null,
            addActivityRegistrationVisible: false,
            text: window.location.href,
            token: "",
        };
    },
    components: {
        CustomerService,
        AddActivityRegistration,
    },
    mounted() {
        this.queryInfo();
        let token = this.$store.getters.token;
        if (token) {
            this.token = token;
            let userInfo = this.$store.getters.userInfo;
            let link = window.location.href.split("?")[0];
            this.text = link + "?flowId=" + this.$route.query.flowId + "&userCode=" + userInfo.userCode;
        }
    },
    methods: {
        addActivityRegistration() {
            let _this = this;
            if (!this.token) {
                sessionStorage.setItem("routerCome", this.text);
                this.$modal.warning({
                    title: "请登录后操作",
                    onOk() {
                        _this.$router
                            .push({
                                name: "Login",
                            })
                            .then((r) => {});
                    },
                });
                return;
            }
            if (this.activeInfo.remainingPlaces == 0) {
                this.$message.warning("该活动报名人数已满，无法继续报名!");
                return;
            }
            this.addActivityRegistrationVisible = true;
        },
        closeAddActivityRegistrationModal() {
            this.addActivityRegistrationVisible = false;
        },
        copy() {
            let params = {
                activityId: this.$route.query.flowId,
                userCode: this.$store.getters.userInfo.userCode,
            };
            const _this = this;
            this.$http
                .post("/resource/talentactivityshare/add", params)
                .then((res) => {
                    if (res.rescode == 200) {
                        var tes = _this.$refs.inputText;
                        tes.select(); // 选择对象
                        document.execCommand("Copy");
                        _this.$message.success("复制成功");
                        _this.queryInfo();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        // 收藏
        favoritesChange() {
            const _this = this;
            // console.log(11,this.focus   )
            if (!this.token) {
                sessionStorage.setItem("routerCome", window.location.href);
                this.$modal.warning({
                    title: "请登录后操作",
                    onOk() {
                        _this.$router
                            .push({
                                name: "Login",
                            })
                            .then((r) => {});
                    },
                });
                return;
            }
            let favoritesStatus = _this.favoritesStatus;
            let userInfo = this.$store.getters.userInfo;
            if (!favoritesStatus) {
                let params = {
                    activityId: this.$route.query.flowId,
                    userCode: userInfo.userCode,
                };
                _this.$http
                    .post("/resource/activitycollection/add", params)
                    .then((res) => {
                        _this.$message.success("操作成功!");
                        _this.queryUserActivity();
                        _this.queryInfo();
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                _this.$modal.confirm({
                    title: "确定取消收藏该活动么?",
                    onOk() {
                        return new Promise((resolve, reject) => {
                            const res = _this.deleteActive(resolve, reject);
                            return res;
                        });
                    },
                    onCancel() {},
                });
            }
        },
        deleteActive(resolve, reject) {
            const _this = this;
            this.$http
                .get("/resource/activitycollection/delete?flowId=" + _this.favoritesObj.flowId)
                .then((res) => {
                    resolve();
                    _this.queryUserActivity();
                    _this.queryInfo();
                })
                .catch((err) => {
                    console.log(err);
                    reject();
                });
        },
        // 马上联系
        contact() {
            this.contactVisible = true;
        },
        closeModal() {
            this.contactVisible = false;
        },
        getTime(startTime, endTime) {
            return this.$moment(startTime).format("YYYY-MM-DD HH:mm:ss") + " - " + this.$moment(endTime).format("YYYY-MM-DD HH:mm:ss");
        },
        getActiveState(startTime, endTime) {
            let currentDate = this.$moment().format("x");
            // 开始日期前两天
            let day2 = this.$moment(startTime)
                .subtract(2, "days")
                .format("x");
            if (currentDate < day2) {
                return "立即报名";
            } else if (currentDate > day2 && currentDate < startTime) {
                return "报名截止";
            } else if (currentDate > startTime && currentDate < endTime) {
                return "活动进行中";
            } else if (currentDate > endTime) {
                return "活动已结束";
            }
        },
        queryInfo() {
            const _this = this;
            this.$http
                .get("/resource/activitytraining/query?flowId=" + this.$route.query.flowId)
                .then((res) => {
                    _this.pageLoading = false;
                    _this.activeInfo = res.data;
                    if (_this.$store.getters.token) {
                        // 查询登录用户是否收藏该活动
                        _this.queryUserActivity();
                    }
                })
                .catch((err) => {
                    _this.pageLoading = false;
                });
        },
        queryUserActivity() {
            const _this = this;
            this.$http
                .get("/resource/activitycollection/queryUserActivity?activityId=" + this.$route.query.flowId)
                .then((res) => {
                    if (res.result == "fail") {
                        _this.favoritesStatus = false;
                    } else {
                        _this.favoritesObj = res.data;
                        _this.favoritesStatus = true;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>
<style lang="less" scoped>
#inputText {
    opacity: 0;
    position: fixed;
    bottom: 0;
    left: 200px;
    // display: none;
}
.marginb_8 {
    margin-bottom: 8px;
}
.marginb_16 {
    margin-bottom: 16px;
}
.pageContainer {
    font-size: 16px;
    .IndustryTrainingDetail {
        height: 100%;
        padding: 16px;
        background: #fff;
        overflow-y: auto;
        /deep/ .ant-spin {
            width: 100%;
            height: 100%;
        }
        .infoBox {
            padding: 8px 0;
            .title {
                padding-bottom: 8px;
                margin-bottom: 8px;
                border-bottom: 1px solid #dfdfdf;
            }
        }
        .info {
            padding-top: 8px;
            .imgBox {
                width: 100%;
                height: 220px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .share {
                margin-top: 8px;
            }
            .activeDetail {
                padding: 8px;
                border: 1px solid #dfdfdf;
                margin: 16px 0;
                border-radius: 6px;
                div {
                    margin-bottom: 8px;
                }
                div:last-child {
                    margin-bottom: 0;
                }
            }
            .discount {
                background: #bae7ff;
                padding: 4px;
                color: #1890ff;
            }
            .prompt {
                margin-top: 15px;
            }
        }
    }
}
</style>
