<template>
    <div class="pageContainer">
        <a-row class="IndustryTraining">
            <a-spin :spinning="pageLoading">
                <a-breadcrumb>
                    <a-breadcrumb-item>
                        培训列表
                    </a-breadcrumb-item>
                </a-breadcrumb>
                <a-form-model layout="inline" ref="ruleForm" :model="formInline" @submit="handleSubmit" @submit.native.prevent class="choose">
                    <a-form-model-item prop="promotionMethods">
                        <!-- <a-cascader :options="options" placeholder="请选择人才类型" v-model="formInline.promotionMethods" /> -->
                        <a-select v-model="formInline.promotionMethods" style="width: 150px" placeholder="全部" allowClear>
                            <a-select-option value="0">
                                全部
                            </a-select-option>
                            <a-select-option value="1">
                                精选
                            </a-select-option>
                            <a-select-option value="2">
                                我的收藏
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item class="search">
                        <a-dropdown-button type="primary" html-type="submit">
                            <a-icon type="search" />查询
                            <a-menu slot="overlay">
                                <a-menu-item key="1" @click="reset">重置查询选项</a-menu-item>
                            </a-menu>
                        </a-dropdown-button>
                    </a-form-model-item>
                </a-form-model>
                <a-row class="activeList" :gutter="16">
                    <a-col :span="8" v-for="item in dataList" :key="item.flowId" class="box" @click="activeClick(item.flowId)">
                        <a-tag class="tag" v-if="item.promotionMethods == 1" color="#fa541c">精选</a-tag>
                        <div class="tagall">
                            <div class="imgBox">
                                <img :src="staticBaseUrl + item.activityUrl" width="100%" height="100%" />
                            </div>
                            <div class="activeName">{{ item.activityName }}</div>
                            <div class="flex justify-between activeDate">
                                <span>{{ getActiveState(item.activityStartTime, item.activityEndTime) }}</span>
                                <span>{{ getTime(item.activityStartTime) }}</span>
                            </div>
                            <div class="flex justify-between">
                                <span>已报名人数{{ item.applicantsSum ? item.applicantsSum : 0 }}人</span>
                                <span class="red">￥{{ item.activityPrice ? item.activityPrice / 1000 : 0 }}</span>
                            </div>
                        </div>
                    </a-col>
                    <a-empty v-if="dataList.length === 0" />
                </a-row>
                <div class="pagination">
                    <a-pagination v-model="current" :total="totalCount" :pageSize="pageSize" size="small" @change="pagenationChange" />
                </div>
            </a-spin>
        </a-row>
    </div>
</template>
<script>
export default {
    data() {
        return {
            pageNum: 0,
            pageSize: 9,
            current: 1,
            pageLoading: true,
            dataList: [],
            totalCount: 0,
            staticBaseUrl: process.env.VUE_APP_RESOURCES_HOST,
            formInline: {
                promotionMethods: undefined,
            },
        };
    },
    created() {
        this.queryPageList(this.pageNum, this.pageSize);
    },
    methods: {
        reset() {
            this.$refs.ruleForm.resetFields();
            this.pageNum = 0;
            this.current = 1;
            this.queryPageList(this.pageNum, this.pageSize);
        },
        pagenationChange(page, pageSize) {
            this.queryPageList(page - 1, pageSize);
        },
        getTime(time) {
            return this.$moment(time).format("YYYY-MM-DD HH:mm:ss");
        },
        activeClick(flowId) {
            this.$router.push({
                name: "IndustryTrainingDetail",
                query: { flowId: flowId },
            });
        },
        getActiveState(startTime, endTime) {
            let currentDate = this.$moment().format("x");
            if (currentDate < startTime) {
                return "未开始";
            } else if (currentDate > startTime && currentDate < endTime) {
                return "进行中";
            } else if (currentDate > endTime) {
                return "已结束";
            }
        },
        handleSubmit(e, pageNum, pageSize) {
            let that = this;
            let params = {
                pageNum: pageNum || pageNum == 0 ? pageNum : 0,
                pageSize: pageSize ? pageSize : that.pageSize ? that.pageSize : 10,
            };
            if (this.formInline.promotionMethods && this.formInline.promotionMethods != "0") {
                params.promotionMethods = Number(this.formInline.promotionMethods);
            }
            const _this = this;
            if (!params.promotionMethods) {
                this.pageNum = 0;
                this.current = 1;
                this.queryPageList(this.pageNum, this.pageSize);
            } else if (params.promotionMethods == 1) {
                _this.pageLoading = true;
                this.pageNum = 0;
                this.current = 1;
                this.$http
                    .post("/resource/activitytraining/queryPageList", params)
                    .then((res) => {
                        _this.dataList = res.data.dataList;
                        _this.totalCount = res.data.totalCount;
                        _this.pageLoading = false;
                    })
                    .catch((err) => {
                        _this.pageLoading = false;
                    });
            } else {
                delete params.promotionMethods;
                _this.pageLoading = true;
                this.pageNum = 0;
                this.current = 1;
                params.userCode = this.$store.getters.userInfo.userCode;
                this.$http
                    .post("/resource/activitycollection/queryPageList", params)
                    .then((res) => {
                        _this.dataList = res.data.dataList;
                        _this.totalCount = res.data.totalCount;
                        _this.pageLoading = false;
                    })
                    .catch((err) => {
                        _this.pageLoading = false;
                    });
            }
        },
        queryPageList(pageNum, pageSize) {
            const _this = this;
            let params = {
                pageNum: pageNum,
                pageSize: pageSize,
            };
            if (this.formInline.promotionMethods && this.formInline.promotionMethods != "0") {
                params.promotionMethods = Number(this.formInline.promotionMethods);
            }
            // _this.pageLoading = true;
            // this.$http
            //     .post("/resource/activitytraining/queryPageList", params)
            //     .then((res) => {
            //         _this.dataList = res.data.dataList;
            //         _this.totalCount = res.data.totalCount;
            //         _this.pageLoading = false;
            //     })
            //     .catch((err) => {
            //         _this.pageLoading = false;
            //     });
            if (!params.promotionMethods) {
                this.$http
                    .post("/resource/activitytraining/queryPageList", params)
                    .then((res) => {
                        _this.dataList = res.data.dataList;
                        _this.totalCount = res.data.totalCount;
                        _this.pageLoading = false;
                    })
                    .catch((err) => {
                        _this.pageLoading = false;
                    });
            } else if (params.promotionMethods == 1) {
                this.$http
                    .post("/resource/activitytraining/queryPageList", params)
                    .then((res) => {
                        _this.dataList = res.data.dataList;
                        _this.totalCount = res.data.totalCount;
                        _this.pageLoading = false;
                    })
                    .catch((err) => {
                        _this.pageLoading = false;
                    });
            } else {
                delete params.promotionMethods;
                params.userCode = this.$store.getters.userInfo.userCode;
                this.$http
                    .post("/resource/activitycollection/queryPageList", params)
                    .then((res) => {
                        _this.dataList = res.data.dataList;
                        _this.totalCount = res.data.totalCount;
                        _this.pageLoading = false;
                    })
                    .catch((err) => {
                        _this.pageLoading = false;
                    });
            }
        },
    },
};
</script>
<style lang="less" scoped>
.red {
    color: #f5222d;
}
.pageContainer {
    .IndustryTraining {
        padding: 16px;
        height: 100%;
        background: #fff;
        .pagination {
            display: flex;
            justify-content: flex-end;
        }
        .activeList {
            .box {
                margin-bottom: 8px;
                position: relative;
                cursor: pointer;
                .tag {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
                .tagall {
                    height: 100%;
                    width: 100%;
                }
                .imgBox {
                    height: 160px;
                    margin-bottom: 8px;
                    border-radius: 5px;
                    overflow: hidden;
                }
                .activeDate {
                    margin: 8px 0;
                }
            }
        }
    }
}
</style>
