<template>
    <div class="mobile flex align-center">
        <div class="imgBox">
            <img :src="defaultImgUrl" />
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            defaultImgUrl: "https://resources.xbov.cn/img/hxznggfwpt/mobile_bg_img.jpg",
        };
    },
    created() {
        if (document.domain == "bc.zbov.cn") {
            this.defaultImgUrl = "https://resources.xbov.cn/img/hxznggfwpt/mobile_bg_img_bc.png";
            document.title = "智能广告服务平台:广告合规检测|极限广告用语|广告违禁词查询|广告法敏感语|广告投放监测|文字广告检测|图片广告检测|音频广告检测|视频广告检测";
        }
    },
};
</script>
<style lang="less" scoped>
.mobile {
    height: 100%;
    background: #fff;
    .imgBox {
        width: 100%;
        img {
            width: 100%;
        }
        div {
            text-align: center;
            font-size: 100px;
            color: #2c7af8;
        }
    }
}
</style>
