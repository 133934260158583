<template>
    <div class="pageContainer">
        <a-row class="financialManagement">
            <a-spin :spinning="pageLoading">
                <a-breadcrumb>
                    <a-breadcrumb-item><a @click="getTalentPool">法律咨询</a></a-breadcrumb-item>
                    <a-breadcrumb-item>律师</a-breadcrumb-item>
                </a-breadcrumb>
                <a-row class="titleRow">
                    <span class="longString"></span>
                    个人信息
                </a-row>
                <a-row class="details">
                    <a-col :span="2" :offset="3">
                        <img :src="baseImgUrl + details.photoUrl" width="90%" height="100px" />
                        <div class="share">
                            <span>分享：</span>
                            <a-button icon="link" @click="copy()" />
                        </div>
                    </a-col>
                    <a-col :span="12" class="row-center">
                        <a-row class="row">
                            <a-col :span="12">
                                <span>{{ details.name }}</span>
                                &nbsp;&nbsp;
                                <span>{{ details.level == 1 ? "初级" : details.level == 2 ? "中级" : details.level == 3 ? "高级" : "" }}</span>
                                <span>{{ details.type == 1 ? "人才" : details.type == 2 ? "专家" : details.type == 3 ? "律师" : "" }}</span>
                            </a-col>
                            <a-col :span="12">
                                <!-- &nbsp;&nbsp;&nbsp;&nbsp; -->
                                <span>擅长:</span>
                                <span>{{ details.areasExpertise }}</span>
                            </a-col>
                        </a-row>
                        <a-row class="row">
                            <!-- <a-col :span="12">
                                <span>联系人数：</span>
                                <span>{{ details.contactSum }}人</span>
                            </a-col> -->
                            <a-col :span="12">
                                <!-- &nbsp;&nbsp;&nbsp;&nbsp; -->
                                <span>粉丝用户：</span>

                                <span>{{ details.fansNumber == null ? 0 : details.fansNumber }}人</span>
                            </a-col>
                        </a-row>

                        <a-row class="row">
                            <span>律师个人案例：</span>
                            <!-- <a>{{ details.caseLinks == "" ? "--" : details.caseLinks }}</a> -->
                            <template v-if="caseLinks.length != 0">
                                <a v-for="(item, index) in caseLinks" :key="index" :href="item.indexOf('http') ? 'http://' + item : item" target="_blank">{{ item }}</a>
                            </template>
                            <template v-else>
                                <span>无</span>
                            </template>
                        </a-row>
                    </a-col>
                    <a-col :span="4">
                        <a-row style="marginTop:5px">
                            <a>
                                <span @click="getInformation">查看律师资料</span>
                            </a>
                        </a-row>

                        <a-button :type="!focus ? 'primary' : ''" class="focusBtn" @click="focusBtn">
                            {{ !focus ? "关注" : "已关注" }}
                        </a-button>
                    </a-col>
                </a-row>
                <a-divider />
                <a-row class="details">
                    <a-col :offset="17" class="charge">
                        <a-button type="primary" @click="contact">
                            马上联系
                        </a-button>
                    </a-col>
                </a-row>
                <a-divider />
                <a-row class="titleRow">
                    <span class="longString"></span>
                    律所介绍
                </a-row>

                <a-row>
                    <a-col :offset="3" :span="15">
                        <p class="introduce">{{ details.lawFirm == null ? "暂无介绍" : details.lawFirm }}</p>
                    </a-col>
                </a-row>

                <!-- 用于存放并复制当前链接的input -->
                <input type="text" v-model="text" readonly="readonly" id="inputText" ref="inputText" />
            </a-spin>
        </a-row>
        <CustomerService :visible="contactVisible" :closeModal="closeModal" :goType="goType" />
        <ContactModel :meetVisible="meetVisible" :meetCloseModal="meetCloseModal" :infoData="commentsList" :type="type"></ContactModel>
    </div>
</template>

<script>
import CustomerService from "../Home/components/CustomerService.vue";
import ContactModel from "./components/ContactModel.vue";
import infiniteScroll from "vue-infinite-scroll";
import store from "../../store";
export default {
    directives: { infiniteScroll },
    components: {
        CustomerService,
        ContactModel,
    },
    data() {
        return {
            focus: false,
            contactVisible: false,
            goType: "customerService",
            meetVisible: false,
            data: [],
            commentsList: {},
            details: {},
            caseLinks: [],
            baseImgUrl: process.env.VUE_APP_RESOURCES_HOST,
            type: "",
            loading: false,
            busy: false,
            flowId: 0,
            // 谈合作
            cooperations: [],
            // 约见面
            meets: [],
            text: window.location.href,
            token: "",
            pageLoading: true,
        };
    },

    mounted() {
        let token = store.getters.token;
        if (token) {
            this.OnFocus();
            this.token = token;
        } else {
            this.getCommentsList();
        }
    },
    methods: {
        getTalentPool() {
            this.$router.push({
                name: "Attorney",
                query: {},
            });
        },
        copy() {
            var tes = this.$refs.inputText;
            tes.select(); // 选择对象
            document.execCommand("Copy");
            this.$message.success("复制成功");
        },
        handleInfiniteOnLoad() {},
        busyScroll() {},
        getInformation() {
            this.$store.dispatch("saveUsertalentInfo", this.details);
            this.$router.push({
                name: "IawyerInformation",
                query: { flowId: this.$route.query.flowId },
            });
        },
        // 是否关注
        OnFocus() {
            let userTalentId = this.$route.query.flowId;
            this.$http
                .post("/resource/usertalentfollow/queryUserTalent?userTalentId=" + userTalentId)
                .then((res) => {
                    if (res.rescode == 200) {
                        this.focus = true;
                        this.flowId = res.data.flowId;
                        this.getCommentsList();
                    } else {
                        this.focus = false;
                        this.getCommentsList();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getCommentsList() {
            var flowId = this.$route.query.flowId;
            this.$http
                .get("/resource/usertalent/query?flowId=" + flowId)
                .then((res) => {
                    if (res.rescode == 200) {
                        this.details = res.data;
                        this.caseLinks = this.details.caseLinks.split(",").filter((item) => item != "");
                        this.commentsList = res.data;
                        this.cooperations = this.commentsList.userTalentCooperationVoList.filter((item) => {
                            return item.cooperationMode == 1;
                        });
                        this.meets = this.commentsList.userTalentCooperationVoList.filter((item) => {
                            return item.cooperationMode == 2;
                        });
                        this.data = [...[], ...res.data.orderReviewVoList];
                    } else {
                        this.$message.warning(res.msg);
                    }
                    this.pageLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.pageLoading = false;
                });
        },
        // 关注按钮
        focusBtn() {
            let _this = this;
            if (!this.token) {
                sessionStorage.setItem("routerCome", window.location.href);
                this.$modal.warning({
                    title: "请登录后操作",
                    onOk() {
                        _this.$router
                            .push({
                                name: "Login",
                            })
                            .then((r) => {});
                    },
                });
                return;
            }
            if (this.focus) {
                this.$modal.confirm({
                    title: "确定取消关注么?",
                    onOk() {
                        return new Promise((resolve, reject) => {
                            const res = _this.deleteActive(resolve, reject);
                            return res;
                        });
                    },
                    onCancel() {},
                });
            } else {
                let params = {
                    userCode: this.$store.getters.userInfo.userCode,
                    userTalentId: this.$route.query.flowId,
                };
                this.$http
                    .post("/resource/usertalentfollow/add", params)
                    .then((res) => {
                        if (res.rescode == 200) {
                            this.focus = true;
                            this.OnFocus();
                        } else {
                            this.$message.warning(res.msg);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        deleteActive(resolve, reject) {
            this.$http
                .post("/resource/usertalentfollow/delete?flowId=" + this.flowId)
                .then((res) => {
                    if (res.rescode == 200) {
                        this.focus = false;
                        this.getCommentsList();
                    } else {
                        this.$message.warning(res.msg);
                    }
                    resolve();
                })
                .catch((error) => {
                    console.log(error);
                    reject();
                });
        },
        // 马上联系
        contact() {
            this.contactVisible = true;
        },
        closeModal() {
            this.contactVisible = false;
        },
        // 约见面，请顾问
        // meet(type) {
        //     let _this = this;
        //     if (!this.token) {
        //         sessionStorage.setItem("routerCome", window.location.href);
        //         this.$modal.warning({
        //             title: "请登录后操作",
        //             onOk() {
        //                 _this.$router
        //                     .push({
        //                         name: "Login",
        //                     })
        //                     .then((r) => {});
        //             },
        //         });
        //         return;
        //     }
        //     this.meetVisible = true;
        //     this.type = type;
        //     var flowId = this.$route.query.flowId;
        // },
        meetCloseModal() {
            this.meetVisible = false;
        },
        fetchData(callback) {
            request({
                url: fakeDataUrl,
                type: "json",
                method: "get",
                contentType: "application/json",
                success: (res) => {
                    callback(res);
                },
            });
        },
        handleInfiniteOnLoad() {
            const data = this.data;
            if (data.length > 0) {
                this.loading = true;
            } else {
                this.loading = false;
                return;
            }

            if (data.length > 14) {
                this.$message.warning("Infinite List loaded all");
                this.busy = true;
                this.loading = false;
                return;
            }

            this.fetchData((res) => {
                this.data = data.concat(res.results);
                this.loading = false;
            });
        },
    },
};
</script>

<style lang="less" scoped>
.share {
    margin: 10px 0;
}
.titleRow {
    // margin-top: 30px;
    font-size: 20px;
    position: relative;
    margin-left: 8%;
    padding: 15px 0;
    .longString {
        width: 3px;
        height: 25px;
        background-color: #2d7bf8;
        position: absolute;
        top: 12px;
        left: -10px;
    }
}
.pageContainer {
    #inputText {
        opacity: 0;
        position: fixed;
        bottom: 0;
        left: 200px;
        // display: none;
    }
    span {
        font-size: 14px;
    }
    a {
        font-size: 14px;
    }
    .introduce {
        font-size: 14px;
        margin: 10px 0;
        padding: 20px;
        border: 1px solid #e8e8e8;
        border-radius: 10px;
        min-height: 200px;
        overflow: auto;
    }
    .financialManagement {
        padding: 16px;
        height: 100%;
        background: #fff;
        overflow: auto;
        .choose {
            margin-top: 10px;
        }
        .button {
            margin-top: 3px;
            margin-right: 20px;
            color: #999999;
        }
        .details {
            // margin: 50px 100px 0 140px;
            // padding: 50px 100px 0 140px;
            margin-top: 50px;
            margin-bottom: 15px;
            .row-center {
                line-height: 50px;
            }
            .focusBtn {
                margin-top: 70px;
                width: 100px;
                display: block;
                // margin-left: 20px;
            }
            .chargeStandard {
                font-size: 14px;
                margin-bottom: 20px;
                .ant-row {
                    margin-top: 10px;
                }
            }
            .charge {
                .ant-btn {
                    width: 100px;
                    margin-bottom: 20px;
                    display: block;
                }
            }
            .commentsList {
                margin-top: 10px;
                height: 200px;
                border: gray 1px solid;
                overflow: auto;
            }
            .demo-infinite-container {
                border: 1px solid #e8e8e8;
                border-radius: 4px;
                overflow: auto;
                padding: 8px 24px;
                height: 200px;
            }
            .demo-loading-container {
                position: absolute;
                bottom: 40px;
                width: 100%;
                text-align: center;
            }
        }
        .talentList {
            margin: 20px 30px;
            height: 120px;
            border-bottom: 1px solid #eee;
            .detailsBtn {
                margin-top: 45px;
            }
        }
    }
}
</style>
