<template>
	<!-- :maskClosable="false" 弹窗支持点击阴影关闭 -->
	<a-modal class="illegalAdSubModal" :footer="false" :maskClosable="false" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel" :width="goType != 'legalConsulting' && goType != 'medical' ? '400px' : '1000px'">
		<a-row class="row" v-if="goType != 'legalConsulting' && goType != 'medical'">
			<img :src="src" />
		</a-row>
		<a-row class="row" v-if="goType == 'customerService'">
			提示：请添加人工客服微信
		</a-row>
		<a-row class="row" v-if="goType == 'customerService'">
			服务时间：周一至周六 09:00-18:00
		</a-row>
		<a-row class="row" v-if="goType == 'OnTheMonitor'">
			广告第三方监播服务请联系客服开通
		</a-row>
		<a-row class="rowHeader" v-if="goType == 'legalConsulting'">
			<h2>《智广宝-鸿星智能广告服务平台》</h2>
			<h2>使用声明</h2>
			<p class="content">广告公司因为其行业特性在日常经营过程中存在大量的法律风险，包括但不仅限于广告合同风险、广告内容风险等。根据调查，超过90%的有民事诉讼，其中主要是经济合同纠纷。超过95%的广告公司法务建设薄弱，缺乏专业律师提供专业支持。</p>
			<p class="content">
				<span class="placeholder"></span>
				《智广宝-鸿星智能广告服务平台》开创广告行业法律服务共享模式。连接广告公司与行业法律专家，为广告行业中小微企业提供法律服务。仅提供线上对接或中介服务，所有服务依托拥有专业资质的律师或律师事务所。从公司日常法律咨询和合同审查服务等方面，努力打造一个大家都用得起的专业高效的线上法律服务平台。
			</p>
			<p class="content title">您使用《智广宝-鸿星智能广告服务平台》本项服务即表示您同意以下内容：</p>
			<a-row class="secondContent">
				<a-col span="2" class="num">1、</a-col>
				<a-col span="22">本平台是鸿星智能公司名下经合法登记注册的广告法律服务线上对接平台。</a-col>
			</a-row>
			<a-row class="secondContent">
				<a-col span="2" class="num">2、</a-col>
				<a-col span="22">本平台的服务内容为：帮助平台会员寻找并对接法律服务。</a-col>
			</a-row>
			<a-row class="secondContent">
				<a-col span="2" class="num">3、</a-col>
				<a-col span="22">会员使用本平台前已经熟知平台的服务内容并同意向平台支付相关费用。</a-col>
			</a-row>
			<a-row class="secondContent">
				<a-col span="2" class="num">4、</a-col>
				<a-col span="22">本平台提供的仅为线上对接技术支撑服务，会员通过平台与第三方合作的权利义务以其与第三方签订的相关合同或协议为准，本平台及平台运营商不承担任何不利法律后果。</a-col>
			</a-row>
			<a-row class="secondContent">
				<a-col span="2" class="num">5、</a-col>
				<a-col span="22">本声明的最终解释权归属与平台及平台运营商。</a-col>
			</a-row>
			<p class="content title">服务方式与成本：</p>
			<p class="content">普通会员：合同审查 188元/件</p>
			<p class="content"><span class="right">法律咨询 288元/件</span></p>
			<p class="content">全年会员：合同审查 1880元/年</p>
			<p class="content"><span class="right">法律咨询 2980元/年</span></p>
			<p class="content">法律服务：普通法律服务按件计费</p>
			<p class="content"><span class="right">风险保障服务标的15%</span></p>
			<p class="content title">《智广宝-鸿星智能广告服务平台》法律服务与法律支持提供方：</p>
			<p class="content">（排名不分先后）湖南揽胜律师事务所、湖南西辰律师事务所、湖南金州律师事务所。</p>
			<a-row class="row" v-if="goType == 'legalConsulting'">
				<img :src="src" />
			</a-row>
			<p class="content footer">合同审查或法律咨询请联系：18175976240</p>
		</a-row>
		<a-row class="rowHeader" v-if="goType == 'medical'">
			<h2>选择您需要审批的类目</h2>
			<div>
				<a-button type="primary" class="button" @click="examine('medical')">医疗器械广告审批</a-button>
				<a-button type="primary" class="button" @click="examine('health')">保健品食品广告审批</a-button>
			</div>
			<div>
				<a-button type="primary" class="button" @click="examine('drug')">药品广告审批</a-button>
				<a-button type="primary" class="button" @click="examine('advertisement')">医疗广告审批</a-button>
			</div>
		</a-row>
		<Examine :examineVisible="examineVisible" :closeExamineModal="closeExamineModal" :goExamineType="goExamineType" />
	</a-modal>
</template>

<script>
import Examine from './Examine';
export default {
	props: ['visible', 'closeModal', 'goType'],
	components: {
		Examine,
	},
	data() {
		return {
			confirmLoading: false,
			src: 'https://resources.xbov.cn/img/hxznggfwpt/WeChat.jpg',
			examineVisible: false,
			goExamineType: '',
		};
	},
	methods: {
		handleCancel() {
			this.closeModal();
		},
		handleOk() {
			this.confirmLoading = true;
			setTimeout(() => {
				this.closeModal();
				this.confirmLoading = false;
				this.$router.push({
					name: 'FinancialManagement',
					query: {},
				});
			}, 2000);
		},
		examine(type) {
			this.examineVisible = true;
			this.goExamineType = type;
		},
		closeExamineModal() {
			this.examineVisible = false;
		},
		// goMedical() {
		//     window.open("https://auth.zwfw.hunan.gov.cn/oauth2/authorize?client_id=sXK6HBx3QwuJqaMXqmx2fQ&response_type=gov&redirect_uri=http://zwfw-new.hunan.gov.cn:80/oauth2-login?backUrl=http://zwfw-new.hunan.gov.cn:80/hnvirtualhall/index.jsp");
		// },
	},
};
</script>

<style lang="less" scoped>
.illegalAdSubModal {
	/deep/ .userPhoneSmsInput {
		width: 78%;
	}
	.row {
		margin-top: 10px;
		font-size: 20px;
		text-align: center;
		img {
			width: 200px;
			height: 200px;
		}
	}
	.rowHeader {
		margin-top: 10px;
		font-size: 30px;
		text-align: center;
		.content {
			font-size: 20px;
			text-align: left;
			margin-top: 10px;
			text-indent: 2em;
			// .placeholder {
			//     width: 20px;
			//     display: inline-block;
			// }

			.right {
				margin-left: 100px;
			}
		}
		.footer {
			text-align: center;
		}
		.title {
			font-weight: 700;
		}
		.secondContent {
			font-size: 20px;
			text-align: left;
			margin-top: 10px;
			.num {
				text-align: right;
			}
		}
		.button {
			width: 300px;
			height: 50px;
			font-size: 20px;
			margin-left: 50px;
			margin-top: 50px;
			border-radius: 25px;
		}
	}
}
</style>
