<template>
    <div>
        <a-modal :width="600" :maskClosable="false" :visible="addNewPutIn" :confirmLoading="spinning" @ok="submit" @cancel="closeModal">
            <a-spin :spinning="spinning">
                <a-form-model ref="putObjForm" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules">
                    <a-form-model-item label="媒介名称" prop="mediaId">
                        <a-select v-model="form.mediaId" placeholder="请选择媒介名称">
                            <a-select-option :key="item.id" :value="item.id" v-for="item in mediaList">{{ item.name }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="投放频次" prop="dayRate">
                        <a-radio-group v-model="form.dayRate">
                            <a-radio :value="1">全天</a-radio>
                            <a-radio :value="2">半天</a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item label="投放次数" prop="countNum">
                        <a-input-number class="numInput" v-model="form.countNum" :min="1"></a-input-number>
                    </a-form-model-item>
                    <a-form-model-item label="投放秒数" prop="secondNum">
                        <a-input-number class="numInput" v-model="form.secondNum" :min="1"></a-input-number>
                    </a-form-model-item>
                    <a-form-model-item label="选择日期" prop="times">
                        <a-range-picker :placeholder="['开始时间', '结束时间']" v-model="form.times" />
                    </a-form-model-item>
                </a-form-model>
            </a-spin>
        </a-modal>
    </div>
</template>
<script>
export default {
    props: ["addNewPutIn", "closeAddNewPutIn", "add"],
    data() {
        return {
            spinning: true,
            mediaList: [],
            value: 1,
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
            timelist: [],
            minAddTime: "",
            maxAddTime: "",
            form: {
                times: [],
                mediaId: "",
                dayRate: 1,
                countNum: "",
                secondNum: "",
            },
            rules: {
                mediaId: [{ required: true, trigger: "change", message: "请输入媒介名称" }],
                times: [{ required: true, message: "请输入投放日期", trigger: "change" }],
                dayRate: [{ required: true, message: "请输入投放频次", trigger: "blur" }],
                countNum: [{ required: true, trigger: "change", message: "请输入投放次数" }],
                secondNum: [{ required: true, trigger: "change", message: "请输入投放秒数" }],
            },
        };
    },
    watch: {
        addNewPutIn(data) {
            if (data) {
                this.getMediaList();
            }
        },
    },
    methods: {
        // handleSearch(e) {
        //     console.log(e);
        //     const _this = this;
        //     _this.getMediaList(e);
        // },
        getMediaList(name) {
            let params = {};
            if (name) {
                params.name = name;
            }
            const _this = this;
            _this.$http
                .post("/publishmedia/queryList", params)
                .then((res) => {
                    _this.spinning = false;
                    if (res.rescode == 200) {
                        _this.mediaList = res.data.dataList;
                    }
                })
                .catch((err) => {
                    _this.spinning = false;
                });
        },
        submit() {
            const _this = this;
            this.$refs.putObjForm.validate((valid) => {
                if (valid) {
                    let mediaName = "";
                    _this.mediaList.map((item) => {
                        if (item.id == _this.form.mediaId) {
                            mediaName = item.name;
                        }
                    });
                    let obj = {
                        dayRate: _this.form.dayRate,
                        mediaName: mediaName,
                        mediaId: _this.form.mediaId,
                        countNum: _this.form.countNum,
                        secondNum: _this.form.secondNum,
                        beginTime: _this.$moment(_this.form.times[0]).format("YYYY-MM-DD"),
                        endTime: _this.$moment(_this.form.times[1]).format("YYYY-MM-DD"),
                    };
                    _this.add(obj);
                    this.$refs.putObjForm.resetFields();
                }
            });
        },
        onChangeBeginTime(date, dateString) {
            console.log(date, dateString);
        },
        //添加时间
        addTime(value) {
            this.minAddTime = value[0];
            this.maxAddTime = value[1];
        },
        closeModal() {
            this.spinning = false;
            this.closeAddNewPutIn();
        },
        onChange(e) {
            console.log(e);
        },
    },
};
</script>
<style lang="less" scoped>
.numInput {
    width: 100%;
}
</style>
