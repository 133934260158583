<template>
    <a-modal class="AddActivityRegistrationModal" :title="false" :footer="false" :visible="addActivityRegistrationVisible" :width="1000" @cancel="handleCancel">
        <a-descriptions title="活动信息" :column="2">
            <a-descriptions-item label="活动名称">
                {{ activeInfo.activityName }}
            </a-descriptions-item>
            <a-descriptions-item label="活动时间">
                {{ getTime(activeInfo.activityStartTime, activeInfo.activityEndTime) }}
            </a-descriptions-item>
            <a-descriptions-item label="已报名人数"> {{ activeInfo.applicantsSum ? activeInfo.applicantsSum : 0 }}人 </a-descriptions-item>
            <a-descriptions-item label="剩余报名人数"> {{ activeInfo.remainingPlaces }}人 </a-descriptions-item>
            <a-descriptions-item label="活动价格(每人)"> ￥{{ activeInfo.activityPrice / 1000 }} </a-descriptions-item>
            <a-descriptions-item label="地址"> {{ activeInfo.activityAddress }} </a-descriptions-item>
        </a-descriptions>
        <a-descriptions title="填写报名信息" :column="1">
            <a-descriptions-item label="报名人数">
                <a-input-number v-model="peopleNum" :min="1" :max="activeInfo.activityQuota"></a-input-number>
            </a-descriptions-item>
            <a-descriptions-item label="联系姓名">
                <a-input v-model="userName" placeholder="请输入中文姓名"></a-input>
            </a-descriptions-item>
            <a-descriptions-item style="display: flex; align-center: center;" class="phoneBox" label="手机号码">
                <a-input style="width: 70%" v-model="phone" placeholder="请输入手机号码"></a-input>
                <a-button style="width: 30%" type="primary" :disabled="smsText != '验证码'" @click="getSmsCode">{{ smsText }}</a-button>
            </a-descriptions-item>
            <a-descriptions-item label="验证码">
                <a-input-number class="codeInput" v-model="code" placeholder="请输入数字验证码"></a-input-number>
            </a-descriptions-item>
        </a-descriptions>
        <div class="payBox">
            <span class="totalNum">总计：{{ peopleNum }}人</span>
            <span class="totalPrice"
                >总额：<span>￥{{ amount ? amount : 0 }}</span></span
            >
            <a-button type="danger" @click="pay">立即支付</a-button>
        </div>
        <PayModal v-if="payData" :changeTradeId="changeTradeId" :payData="payData" :payModalVisible="payModalVisible" :tradeId="tradeId" :closePayModal="closePayModal" />
    </a-modal>
</template>
<script>
import PayModal from "./PayModal.vue";
export default {
    props: ["addActivityRegistrationVisible", "closeAddActivityRegistrationModal", "activeInfo"],
    data() {
        return {
            peopleNum: 1,
            userName: "",
            phone: "",
            smsText: "验证码",
            code: "",
            getSmscodeFlag: false,
            amount: null,
            payModalVisible: false,
            tradeId: null,
            payData: null,
            timer: null,
        };
    },
    components: {
        PayModal,
    },
    watch: {
        addActivityRegistrationVisible(data) {
            if (data) {
                this.activityCosting();
            }
        },
        peopleNum(data) {
            if (data) {
                if (data >= 1 && data <= this.activeInfo.activityQuota) {
                    this.activityCosting();
                }
            }
        },
    },
    methods: {
        changeTradeId(data) {
            this.tradeId = data;
        },
        closePayModal() {
            this.payModalVisible = false;
            this.$router.push({ name: "OrderCenter" });
        },
        activityCosting() {
            const _this = this;
            this.$http
                .get("/resource/activitytraining/activityCosting?activityId=" + this.activeInfo.flowId + "&applicantsNumber=" + this.peopleNum)
                .then((res) => {
                    _this.amount = res.data.price;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getSmsCode() {
            if (this.phone == "") {
                this.$message.warning("请输入手机号码!");
                return;
            }
            if (!this.$CommonUtil.phoneTest(this.phone)) {
                this.$message.warning("请输入正确的手机号码!");
                return;
            }
            const _this = this;
            this.$http.get("/login/smsSendCode?phone=" + this.phone + '&type=2').then((res) => {
                // console.log("获取手机验证码", res);
                _this.getSmscodeFlag = true;
                _this.smsText = 60;
                _this.timer = setInterval(() => {
                    _this.smsText -= 1;
                    if (_this.smsText == 0) {
                        clearInterval(_this.timer);
                        _this.smsText = "验证码";
                        _this.getSmscodeFlag = false;
                    }
                }, 1000);
            });
        },
        pay() {
            if (this.userName == "") {
                this.$message.warning("请输入姓名!");
                return;
            }
            if (this.phone == "") {
                this.$message.warning("请输入手机号码!");
                return;
            }
            if (this.code == "") {
                this.$message.warning("请输入短信验证码!");
                return;
            }
            if (!this.$CommonUtil.chineseTest(this.userName)) {
                this.$message.warning("请输入正确的中文姓名!");
                return;
            }
            if (!this.$CommonUtil.phoneTest(this.phone)) {
                this.$message.warning("请输入正确的手机号码!");
                return;
            }
            if (!this.$CommonUtil.numTest(this.code)) {
                this.$message.warning("请输入正确的短信验证码!");
                return;
            }
            if (this.peopleNum > this.activeInfo.remainingPlaces) {
                this.$message.warning("报名人数超过剩余报名人数，无法报名!");
                return;
            }
            let userInfo = this.$store.getters.userInfo;
            let params = {
                activityId: this.activeInfo.flowId,
                amount: this.amount * 1000,
                price: this.activeInfo.activityPrice,
                code: this.code,
                peopleNumber: this.peopleNum,
                phone: this.phone,
                type: 1,
                userCode: userInfo.userCode,
                userName: this.userName,
            };
            if (this.$route.query.userCode) {
                params.sharedPersonId = this.$route.query.userCode;
                params.identification = 1;
            }
            const _this = this;
            this.$http
                .post("/resource/trainorder/add", params)
                .then((res) => {
                    if (res.msg == "操作成功") {
                        _this.tradeId = res.data.orderId;
                        _this.payData = params;
                        setTimeout(() => {
                            _this.payModalVisible = true;
                        }, 1000);
                    } else {
                        this.$message.warning(res.msg);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getTime(startTime, endTime) {
            return this.$moment(startTime).format("YYYY-MM-DD HH:mm:ss") + " - " + this.$moment(endTime).format("YYYY-MM-DD HH:mm:ss");
        },
        handleCancel() {
            this.peopleNum = 1;
            this.userName = "";
            this.phone = "";
            this.code = "";
            this.smsText = "验证码";
            if (this.timer) {
                clearInterval(this.timer);
            }
            this.closeAddActivityRegistrationModal();
        },
    },
};
</script>
<style lang="less" scoped>
.AddActivityRegistrationModal {
    .payBox {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .totalNum,
        .totalPrice {
            margin-right: 8px;
        }
        .totalPrice {
            display: flex;
            align-items: center;
            span {
                font-size: 20px;
                color: #f5222d;
            }
        }
    }
    .codeInput {
        width: 160px;
    }
}
</style>
