<template>
    <div class="pageContainer">
        <a-row class="financialManagement">
            <a-breadcrumb>
                <a-breadcrumb-item><a @click="getTalentPool">专家人才</a></a-breadcrumb-item>
                <a-breadcrumb-item><a @click="getSpecialist">专家</a></a-breadcrumb-item>
                <a-breadcrumb-item>专家资料</a-breadcrumb-item>
            </a-breadcrumb>
            <a-row class="details">
                <a-col :span="16" :offset="2">
                    <a-row class="titleRow">
                        <span class="longString"></span>
                        个人介绍
                    </a-row>
                    <div class="baseInfo">
                        {{ experience }}
                    </div>
                    <!-- <a-textarea v-model="experience" disabled style="margin:20px 0;height:180px" /> -->
                </a-col>
            </a-row>
            <a-divider />
            <a-row class="details">
                <a-col :span="16" :offset="2">
                    <a-row class="titleRow">
                        <span class="longString"></span>
                        个人荣誉
                    </a-row>
                </a-col>

                <a-col :span="16" :offset="2">
                    <div class="honorList">
                        <img v-for="(item, index) in certificateHonor" :key="index" :src="imgBaseUrl + item" class="honor" @click="preview(item)" />
                    </div>
                </a-col>
            </a-row>
            <a-row class="details">
                <a-col :span="16" :offset="2">
                    <a-row class="titleRow">
                        <span class="longString"></span>
                        案例链接
                    </a-row>
                    <div class="linksBox">
                        <a v-for="(item, index) in caseLinks" :key="index" :href="item.indexOf('http') ? 'http://' + item : item" target="_blank" class="links">{{ item }}</a>
                    </div>
                </a-col>
            </a-row>
        </a-row>
        <Vviewer ref="viewer"></Vviewer>
    </div>
</template>

<script>
import Vviewer from "../../components/Vviewer.vue";
export default {
    data() {
        return {
            experience: "",
            userInfo: null,
            certificateHonor: [],
            imgBaseUrl: process.env.VUE_APP_RESOURCES_HOST,
            caseLinks: [],
        };
    },
    components: {
        Vviewer,
    },
    created() {
        this.userInfo = this.$store.getters.usertalentInfo;
        this.experience = this.userInfo.experience;
        this.certificateHonor = this.userInfo.certificateHonor.split(",").filter((item) => item != "");
        this.caseLinks = this.userInfo.caseLinks.split(",").filter((item) => item != "");
    },
    methods: {
        preview(data) {
            this.$refs.viewer.show([
                {
                    thumbnail: this.imgBaseUrl + data,
                    source: this.imgBaseUrl + data,
                },
            ]);
        },
        getTalentPool() {
            this.$router.push({
                name: "TalentPool",
                query: {},
            });
        },
        getSpecialist() {
            this.$router.push({
                name: "Details",
                query: { flowId: this.$route.query.flowId },
            });
        },
    },
};
</script>

<style lang="less" scoped>
.pageContainer {
    .financialManagement {
        padding: 16px;
        height: 100%;
        overflow: auto;
        background: #fff;
        .titleRow {
            // margin-top: 30px;
            font-size: 20px;
            position: relative;
            margin-left: 20px;
            .longString {
                width: 3px;
                height: 25px;
                background-color: #2d7bf8;
                position: absolute;
                top: -3px;
                left: -10px;
            }
        }
        .choose {
            margin-top: 10px;
        }
        .button {
            margin-top: 3px;
            margin-right: 20px;
            color: #999999;
        }
        .details {
            // margin-top: 50px;
            border-bottom: 1px solid #e8e8e8;
            padding: 30px 0;
            .title {
                margin-bottom: 16px;
            }
            .honorList {
                margin-top: 30px;
                .honor {
                    margin-right: 30px;
                    width: 230px;
                    height: 200px;
                }
            }
        }
        .details:last-child {
            border: none;
        }
        .baseInfo,
        .linksBox {
            font-size: 14px;
            margin: 10px 0;
            padding: 20px;
            border: 1px solid #e8e8e8;
            border-radius: 10px;
            min-height: 100px;
            max-height: 200px;
            overflow: auto;
        }

        .links {
            font-size: 14px;
            margin: 20px;
        }
    }
}
</style>
