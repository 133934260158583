<template>
    <a-modal :width="600" :visible="payModalVisible" title="扫码付款" class="payModal" @cancel="closeModal" :footer="false">
        <a-tabs :tabBarGutter="150" @change="changeTabsKey" :activeKey="activeKey">
            <a-tab-pane key="wechatPay" tab="微信支付">
                <a-spin :spinning="getQrcodeLoading">
                    <div class="payCode flex justify-center align-center">
                        <div class="qrcode flex justify-center align-center">
                            <img v-if="qrcodeUrl" :src="resourcesHost + qrcodeUrl" />
                            <div v-else-if="!qrcodeUrl && !getQrcodeLoading" class="getQrcodeFaild" @click="getPayQrcodeAgain">
                                <div>支付二维码获取失败</div>
                                <div>点击重新获取</div>
                            </div>
                            <div class="mask" v-if="!qrcodeUrl && !getQrcodeLoading"></div>
                        </div>
                    </div>
                </a-spin>
                <div class="text payAmount flex align-center justify-center">￥{{ payData.amount / 1000 }}</div>
                <div class="text flex align-center justify-center">扫描二维码支付</div>
            </a-tab-pane>
            <a-tab-pane key="aliPay" tab="支付宝支付">
                <a-spin :spinning="getQrcodeLoading">
                    <div class="payCode flex justify-center align-center">
                        <div class="qrcode flex justify-center align-center ">
                            <img v-if="qrcodeUrl" :src="resourcesHost + qrcodeUrl" />
                            <div v-else-if="!qrcodeUrl && !getQrcodeLoading" class="getQrcodeFaild" @click="getPayQrcodeAgain">
                                <div>支付二维码获取失败</div>
                                <div>点击刷新</div>
                            </div>
                            <div class="mask" v-if="!qrcodeUrl && !getQrcodeLoading"></div>
                        </div>
                    </div>
                </a-spin>
                <div class="text payAmount flex align-center justify-center">￥{{ payData.amount / 1000 }}</div>
                <div class="text flex align-center justify-center">扫描二维码支付</div>
            </a-tab-pane>
        </a-tabs>
    </a-modal>
</template>

<script>
export default {
    props: ["payModalVisible", "payData", "tradeId", "closePayModal", "changeTradeId"],
    data() {
        return {
            currentPayType: 1,
            resourcesHost: process.env.VUE_APP_RESOURCES_HOST,
            qrcodeUrl: null,
            getQrcodeLoading: true,
            getTradeStatusInterval: null,
            activeKey: "wechatPay",
        };
    },
    watch: {
        payModalVisible(data) {
            if (data) {
                this.getPayQrcode();
            }
        },
    },
    methods: {
        getPayQrcodeAgain() {
            this.getQrcodeLoading = true;
            this.qrcodeUrl = null;
            if (this.getTradeStatusInterval) {
                clearInterval(this.getTradeStatusInterval);
            }
            this.getPayQrcode();
        },
        changeTabsKey(key) {
            this.activeKey = key;
            this.getQrcodeLoading = true;
            this.qrcodeUrl = null;
            if (this.getTradeStatusInterval) {
                clearInterval(this.getTradeStatusInterval);
            }
            this.getPayQrcode();
        },
        closeModal() {
            this.getQrcodeLoading = true;
            this.qrcodeUrl = null;
            if (this.getTradeStatusInterval) {
                clearInterval(this.getTradeStatusInterval);
            }
            this.closePayModal();
        },
        getTradeStatus() {
            const _this = this;
            this.$http
                .get("/resource/trainorder/query?orderId=" + this.tradeId)
                .then((res) => {
                    let currentTradePayState = res.data.state;
                    if (currentTradePayState == 1) {
                        _this.closeModal();
                    }
                })
                .catch((err) => {
                    console.log("获取订单状态失败", err);
                });
        },
        getPayQrcode() {
            let url = "/resource/pay/alipay";
            if (this.activeKey == "aliPay") {
                url = "/resource/pay/alipay";
            } else {
                url = "/resource/pay/activityQrcode";
            }
            let params = {
                activityOrderId: this.tradeId,
            };
            const _this = this;
            this.$http
                .post(url, params)
                .then((res) => {
                    if (res.rescode == 200) {
                        _this.getQrcodeLoading = false;
                        _this.qrcodeUrl = res.data.jsonHtml;
                        if (_this.activeKey == "wechatPay" && res.data.outTradeNo) {
                            _this.changeTradeId(res.data.outTradeNo);
                        }
                        if (_this.getTradeStatusInterval) {
                            clearInterval(_this.getTradeStatusInterval);
                        }
                        _this.getTradeStatusInterval = setInterval(() => {
                            _this.getTradeStatus();
                        }, 1000);
                    }
                })
                .catch((err) => {
                    this.qrcodeUrl = null;
                    _this.getQrcodeLoading = false;
                    console.log("获取支付二维码失败", err);
                });
        },
    },
};
</script>

<style lang="less" scoped>
.payModal {
    /deep/.ant-tabs-nav-scroll {
        text-align: center;
    }
    .text {
        font-size: 25px;
    }
    .payAmount {
        color: #fd9608;
        font-size: 38px;
    }
    .payCode {
        .qrcode {
            position: relative;
            width: 220px;
            height: 220px;
            background: url("https://resources.xbov.cn/img/hxznggfwpt/defaultQrcode.jpg") no-repeat;
            background-size: cover;
            .mask {
                position: absolute;
                width: 100%;
                height: 100%;
                background: rgba(22, 22, 22, 0.7);
            }
            /deep/.ant-spin-container {
                font-size: 0;
            }
            .getQrcodeFaild {
                background: #fff;
                padding: 8px 16px;
                border-radius: 50px;
                color: #000;
                z-index: 100;
                cursor: pointer;
                div {
                    text-align: center;
                }
            }
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .label {
        text-align: right;
    }
    .ant-row {
        padding-bottom: 16px;
        font-size: 16px;
    }
}
</style>
