<template>
    <div>
        <a-modal :width="700" :maskClosable="false" :visible="addClient" :title="opClient == 'add' ? '创建客户信息' : '编辑客户信息'" :confirmLoading="spinning" @ok="submit" @cancel="closeModal">
            <a-spin :spinning="spinning">
                <a-form-model ref="clientForm" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules">
                    <a-form-model-item label="客户名称" prop="name" :whitespace="true">
                        <a-input v-model="form.name" placeholder="请输入客户名称" :maxLength="20" />
                    </a-form-model-item>
                    <a-form-model-item label="品牌名称" prop="adName">
                        <a-input v-model="form.adName" placeholder="请输入品牌名称" :maxLength="20" />
                    </a-form-model-item>
                    <a-form-model-item label="联系人" prop="contactName">
                        <a-input v-model="form.contactName" placeholder="请输入联系人" :maxLength="5" />
                    </a-form-model-item>
                    <a-form-model-item label="联系电话" prop="contactPhone">
                        <a-input v-model="form.contactPhone" placeholder="请输入电话号码" :maxLength="11"/>
                    </a-form-model-item>
                    <a-form-model-item label="客户LOGO" prop="logoUrl">
                        <a-upload :action="baseUrl" list-type="picture-card" :before-upload="beforeImgUpload" :file-list="fileList" @preview="handlePreview" @change="handlechange">
                            <div v-if="fileList.length < 1">
                                <a-icon type="plus" />
                                <div class="ant-upload-text">
                                    上传图片
                                </div>
                            </div>
                        </a-upload>
                    </a-form-model-item>
                    <a-form-model-item label="客户介绍" prop="introduction">
                        <a-textarea :auto-size="{ minRows: 5, maxRows: 8 }" v-model="form.introduction" placeholder="请输入客户介绍" :maxLength="300" />
                    </a-form-model-item>
                </a-form-model>
            </a-spin>
        </a-modal>
        <Vviewer ref="viewer"></Vviewer>
    </div>
</template>
<script>
import Vviewer from "../../../components/Vviewer";
export default {
    props: ["addClient", "closeAddClient", "opClient"],
    components:{
        Vviewer,
    },
    data() {
        const _this = this;
        let chekckPic = (rule, value, callback) => {
            if (_this.fileList.length == 0) {
                callback(new Error("请选择LOGO图片"));
            } else {
                callback();
            }
        };
        let chekckName = (rule,value,callback)=>{
            let reg = new RegExp(/^[\u4E00-\u9FA5A-Za-z]+$/g)
            if(!reg.test(value) || value.length == 0){
                callback("请输入用户名称，并不要使用数字");
                return;
            }
            else{
                callback();
            }
        };
        let chekckContactName = (rule,value,callback)=>{
            let reg = new RegExp(/^[\u4e00-\u9fa5]{0,}$/g);
            if(!reg.test(value) || value.length == 0){
                callback("请输入联系人，并只能使用汉字");
                return
            }else{
                callback();
            }
        };
        return {
            spinning: false,
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
            fileList: [],
            baseUrl: process.env.VUE_APP_RESOURCES_API_HOST,
            baseImgUrl: process.env.VUE_APP_RESOURCES_HOST,
            form: {
                name: "",
                adName: "",
                introduction: "",
                contactName: "",
                contactPhone: "",
                logoUrl: "",
            },
            rules: {
                name: [{ required: true, trigger: "change",validator:chekckName}],
                adName: [{ required: true, trigger: "change", message: "请输入品牌名称" }],
                contactName: [{ required: true, trigger: "change",validator: chekckContactName}],
                contactPhone: [
                    { required: true, trigger: "change", message: "请输入电话号码" },
                    { len: 11, message: "请输入正确的手机号码", pattern: /^1[3456789]\d{9}$/ },
                ],
                logoUrl: [{ required: true, validator: chekckPic, message: "请上传LOGO图片", trigger: "submit" }],
            },
            previewVisible: false,
        };
    },
    //在修改框内查看值
    watch: {
        opClient(data) {
            if (data != "add") {
                this.spinning = true;
                const _this = this;
                this.$http.get("/publishcustomer/query?id=" + data).then((res) => {
                    _this.spinning = false;
                    _this.form = {
                        name: res.data.name,
                        adName: res.data.adName,
                        introduction: res.data.introduction,
                        contactName: res.data.contactName,
                        contactPhone: res.data.contactPhone,
                    };
                    _this.fileList = [
                        {
                            uid: "-1",
                            name: "image.png",
                            status: "done",
                            url: this.baseImgUrl + res.data.logoUrl,
                            logoUrl: res.data.logoUrl,
                        },
                    ];
                });
            }
        },
    },
    methods: {
        //上传图片判定条件
        beforeImgUpload(file) {
            return new Promise((resolve, reject) => {
                var testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
                const isLt2M = file.size / 1024 / 1024 <= 2; //图片大小不超过2MB
                if (!testmsg) {
                    this.$message.error("只支持上传图片");
                    return reject(false);
                }
                if (!isLt2M) {
                    this.$message.error("上传图片大小不能超过2MB!");
                    return reject(false);
                }
                return resolve(true);
            });
        },
        //查看大图
        async handlePreview(file) {
                if(file.response && file.response != ""){
                    this.$refs.viewer.show([
                    {
                      thumbnail:process.env.VUE_APP_RESOURCES_HOST+file.response.data.filePath,
                      source:process.env.VUE_APP_RESOURCES_HOST+file.response.data.filePath,
                    },
                ]);
                }else{
                  this.$refs.viewer.show([
                    {
                      thumbnail:process.env.VUE_APP_RESOURCES_HOST+file.logoUrl,
                      source:process.env.VUE_APP_RESOURCES_HOST+file.logoUrl,
                    },
                ]);
            }
        },
        //关闭预览
        handleCancel() {
            this.previewVisible = false;
        },
        //上传图片
        async handlechange({ file, fileList }) {
            if (file.status == "done" && file.response.rescode == 200) {
                fileList.map((item) => {
                    if (item.status == "done") {
                        if (item.response.data.coverImgUrl && item.response.data.coverImgUrl != "") {
                            item.thumbUrl = process.env.VUE_APP_RESOURCES_HOST + item.response.data.coverImgUrl;
                        }
                    }
                });
            } else if (file.status == "done" && file.response.rescode == 201) {
                this.$message.warning(file.response.msg);
                fileList = fileList.filter((item) => {
                    return item.uid != file.uid;
                });
            }
            this.fileList = fileList;
        },
        //确定，接口调用，写入数据
        submit() {
            const _this = this;
            this.$refs.clientForm.validate((valid) => {
                if (valid) {
                    _this.spinning = true;
                    if (_this.opClient == "add") {
                        let params = {
                            name: _this.form.name,
                            adName: _this.form.adName,
                            introduction: _this.form.introduction,
                            contactName: _this.form.contactName,
                            contactPhone: _this.form.contactPhone,
                            logoUrl: this.fileList[0].response.data.filePath,
                        };
                        _this.$http
                            .post("/publishcustomer/add", params)
                            .then((res) => {
                                _this.$message.success("操作成功");
                                this.fileList = [];
                                _this.closeModal();
                            })
                            .catch((err) => {
                                _this.spinning = false;
                            });
                    } else {
                        let params = {
                            id: _this.opClient,
                            name: _this.form.name,
                            adName: _this.form.adName,
                            introduction: _this.form.introduction,
                            contactName: _this.form.contactName,
                            contactPhone: _this.form.contactPhone,
                            logoUrl: this.fileList[0].response ? this.fileList[0].response.data.filePath : this.fileList[0].logoUrl,
                        };
                        _this.$http
                            .post("/publishcustomer/update", params)
                            .then((res) => {
                                _this.$message.success("操作成功");
                                _this.closeModal();
                            })
                            .catch((err) => {
                                _this.spanning = false;
                            });
                    }
                }
            });
        },
        closeModal() {
            this.spinning = false;
            this.$refs.clientForm.resetFields();
            this.fileList = [];
            this.closeAddClient();
        },
    },
};
</script>
