<template>
    <a-modal :visible="refund" @cancel="refundCancel" :maskClosable="false" :footer="null">
        <a-row style="padding:20px 60px">
            <a-row>
                <a-col :span="6" :offset="10">
                    <a-icon type="logout" style="fontSize:100px" />
                </a-col>
            </a-row>
            <a-row style="marginTop:30px">
                <a-col :span="6" :offset="10">
                    <h1>{{state==3?"已退款":state==4?"退款中":"--"}}</h1>
                </a-col>
            </a-row>
            <a-row style="margin:30px 0">
                <a-col :span="14" :offset="2">
                    <span>实际退款金额</span>
                </a-col>
                <a-col :span="6">
                    <h1>{{amount}}元</h1>
                </a-col>
            </a-row>
            <a-row style="margin:30px 0">
                <a-col :span="14" :offset="2">
                    <span>申请退款金额</span>
                </a-col>
                <a-col :span="6">
                    <h1>{{amount}}元</h1>
                </a-col>
            </a-row>
            <a-row style="margin:30px 0">
                <a-col :span="8" :offset="2">
                    <span>预计到账时间</span>
                </a-col>
                <a-col :span="10">
                    <span>预计一个工作日内到账</span>
                </a-col>
            </a-row>
            <a-row style="margin:30px 0">
                <a-col :span="8" :offset="2">
                    <h1>预计到账时间</h1>
                </a-col>
            </a-row>
            <a-row style="margin:30px 0">
                <a-col :span="18" :offset="2">
                    <span>微信会在退款处理完后1天内完成入账，将{{amount}}元入账至您的微信账户</span>
                </a-col>
            </a-row>
        </a-row>
    </a-modal>
</template>

<script>
export default {
    props: ["refund", "refundCancel","amount","state"],
    data() {
        return {
            value: 0,
        };
    },
    methods: {
        handleCancel() {
            this.closeModal();
        },
        handleOk() {
            // this.confirmLoading = true;
            // setTimeout(() => {
            //     this.closeModal();
            //     this.confirmLoading = false;
            //     this.$router.push({
            //         name: "FinancialManagement",
            //         query: {},
            //     });
            // }, 2000);
            this.closeModal();
        },
    },
};
</script>

<style lang="less" scoped>
    /deep/.ant-modal{
        width: 600px!important;
    }
</style>
