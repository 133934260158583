<template>
    <div>
        <a-modal class="putInModal" :width="900" :maskClosable="false" :visible="addPutIn" :title="opPutIn == 'add' ? '创建投放信息' : '修改投放信息'" :confirmLoading="spinning" @ok="submit" @cancel="closeModal">
            <a-spin :spinning="spinning">
                <a-form-model ref="putInForm" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules">
                    <a-form-model-item label="投放名称" prop="name" :whitespace="true">
                        <a-input v-model="form.name" placeholder="请输入投放名称" :maxLength="20" />
                    </a-form-model-item>
                    <a-form-model-item label="投放列表" :label-col="labelCol" :wrapper-col="wrapperCol">
                        <div>
                            <a-button type="primary" @click="creNewPutIn" size="small">新增</a-button>
                            <a-table :data-source="listData" :columns="NewPutIn" :scroll="{ y: 200 }">
                                <span slot="dayRate" slot-scope="text">
                                    {{ text == 1 ? "全天" : "半天" }}
                                </span>
                                <span slot="action" slot-scope="text, record, index">
                                    <a-popconfirm title="确认删除吗？" ok-text="确认" cancel-text="取消" @confirm="delNewPutIn(index)">
                                        <a>删除</a>
                                    </a-popconfirm>
                                </span>
                            </a-table>
                        </div>
                    </a-form-model-item>
                    <a-form-model-item label="投放说明" prop="remake">
                        <a-textarea :auto-size="{ minRows: 5, maxRows: 8 }" v-model="form.remake" placeholdel="请输入投放说明" :maxLength="300"></a-textarea>
                    </a-form-model-item>
                </a-form-model>
            </a-spin>
        </a-modal>
        <AddNewPutIn :addNewPutIn="addNewPutIn" :closeAddNewPutIn="closeAddNewPutIn" :add="add"></AddNewPutIn>
    </div>
</template>
<script>
import AddNewPutIn from "./AddNewPutIn";
export default {
    props: ["addPutIn", "closeAddPutIn", "opPutIn"],
    data() {
        const _this = this;
        return {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
            spinning: false,
            addNewPutIn: false,
            listData: [],
            form: {
                name: "",
                remake: "",
            },
            rules: {
                name: [{ required: true, trigger: "change", message: "请输入投放名称" }],
                remake:[{required: true, trigger: "change", message: "请输入投放说明"}]
            },
            NewPutIn: [
                {
                    title: "媒介名称",
                    key: "mediaName",
                    dataIndex: "mediaName",
                    scopedSlots: { customRender: "mediaName" },
                },
                {
                    title: "投放频次",
                    key: "dayRate",
                    dataIndex: "dayRate",
                    scopedSlots: { customRender: "dayRate" },
                },
                {
                    title: "投放次数",
                    key: "countNum",
                    dataIndex: "countNum",
                    scopedSlots: { customRender: "countNum" },
                },
                {
                    title: "投放秒数",
                    key: "secondNum",
                    dataIndex: "secondNum",
                    scopedSlots: { customRender: "secondNum" },
                },
                {
                    title: "操作",
                    dataIndex: "action",
                    width: 100,
                    scopedSlots: { customRender: "action" },
                },
            ],
        };
    },
    components: {
        AddNewPutIn,
    },
    watch:{
        opPutIn(data){
           if (data != "add") {
                this.spinning = true;
                const _this = this;
                this.$http.get("/publishrelease/query?id=" + data).then((res) => {
                    _this.spinning = false;
                    _this.form = {
                        name:res.data.name,
                        remake:res.data.remake,
                    };
                    res.data.publishReleaseMediaVos.map((item) =>{
                        let obj = {
                            releaseId:data,
                            id:item.id,
                            mediaName:item.mediaName,
                            mediaId:item.mediaId,
                            dayRate:item.dayRate,
                            countNum:item.countNum,
                            secondNum:item.secondNum,
                            beginTime: _this.$moment(item.beginTime).format("YYYY-MM-DD"),
                            endTime:_this.$moment(item.endTime).format("YYYY-MM-DD"),
                        }
                        _this.listData.push(obj);
                    })
                });
            }
        },
    },
    methods: {
        delNewPutIn(index) {
            this.listData = this.listData.filter((item, itemIndex) => {
                return itemIndex != index;
            });
        },
        submit() {
            const _this = this;
            this.$refs.putInForm.validate((valid) => {
                if (valid) {
                    _this.spinning = true;
                    if (_this.opPutIn == "add") {
                        let params = {
                            name: _this.form.name,
                            remake: _this.form.remake,
                            publishReleaseMediaCreateForms: this.listData,
                        };
                        _this.$http
                            .post("/publishrelease/add", params)
                            .then((res) => {
                                if (res.rescode == 200) {
                                    _this.$message.success("操作成功");
                                    _this.closeModal();
                                } else {
                                    _this.spinning = false;
                                    _this.$message.error(res.msg);
                                }
                            })
                            .catch((err) => {
                                _this.spinning = false;
                            });
                    } else {
                        let params = {
                            id:_this.opPutIn,
                            name: _this.form.name,
                            remake: _this.form.remake,
                            publishReleaseMediaUpdateFormList: this.listData,
                        };
                        _this.$http
                            .post("/publishrelease/update", params)
                            .then((res) => {
                                if(res.rescode == 200){
                                    _this.$message.success("操作成功");
                                    _this.closeModal();
                                }else{
                                    _this.spinning = false;
                                    _this.$message.error(res.msg);
                                }
                            })
                            .catch((err) => {
                                _this.spanning = false;
                                
                            });
                    }
                }
            });
        },
        creNewPutIn() {
            this.addNewPutIn = true;
        },
        closeModal() {
            this.closeAddPutIn();
            this.$refs.putInForm.resetFields();
            this.listData = [];
            this.spinning = false;
        },
        closeAddNewPutIn() {
            this.addNewPutIn = false;
        },
        //获取子组件传递来的数据并push的到其中所有数据
        add(obj) {
            this.listData.push(obj);
            this.closeAddNewPutIn();
        },
    },
};
</script>
<style lang="less" scoped>
.putInModal {
    /deep/ .ant-modal-body {
        padding: 8px;
    }
}
</style>
