<template>
    <a-modal class="mapModal" :width="1000" :visible="mapVisible" :maskClosable="false" :footer="false" title="地图选址" @cancel="onClose">
        <a-row class="alertBox">
            <a-alert :message="addressInfo && addressInfo.formattedAddress ? '已选地址：' + addressInfo.formattedAddress : '请点击地图选址'" type="info">
                <span slot="closeText">
                    <a-button type="primary" size="small" :disabled="!addressInfo.formattedAddress" @click="comfirmAdress">确定选择</a-button>
                </span>
            </a-alert>
        </a-row>
        <div id="mapContainer" class="mapContainer">
            <el-amap :events="events" class="amap-box" :vid="'amap-vue'">
                <el-amap-marker v-for="(marker, index) in markers" :position="marker.position" :vid="index" :key="'marker' + index" :editable="true" :draggable="true"></el-amap-marker>
            </el-amap>
        </div>
    </a-modal>
</template>
<script>
import { lazyAMapApiLoaderInstance } from "vue-amap";
export default {
    props: ["mapVisible", "closeMapModal"],
    data() {
        return {
            markers: [],
            addressInfo: {
                formattedAddress: null,
            },
            events: {
                click: this.mapClick,
            },
        };
    },
    methods: {
        comfirmAdress() {
            this.closeMapModal(this.addressInfo);
        },
        async mapClick(e) {
            let { lng, lat } = e.lnglat;
            let marker = {
                position: [lng, lat],
            };
            this.markers = [];
            this.markers.push(marker);
            this.getAddress(lng, lat);
        },
        getAddress(lng, lat) {
            const _this = this;
            let lnglat = [lng, lat];
            lazyAMapApiLoaderInstance.load().then(() => {
                AMap.plugin("AMap.Geocoder", function() {
                    var geocoder = new AMap.Geocoder();
                    geocoder.getAddress(lnglat, function(status, result) {
                        if (status === "complete" && result.regeocode) {
                            _this.addressInfo = result.regeocode;
                            _this.addressInfo.position = [lng, lat];
                        } else {
                            log.error("根据经纬度查询地址失败");
                        }
                    });
                });
            });
        },
        onClose() {
            this.closeMapModal();
        },
    },
};
</script>
<style lang="less" scoped>
.mapContainer {
    width: 100%;
    height: 600px;
}
.mapModal {
    /deep/ .ant-modal-body {
        padding: 0;
        .alertBox {
            padding: 8px;
        }
    }
}
</style>
