<template>
    <!-- :maskClosable="false" 弹窗支持点击阴影关闭 -->
    <a-modal class="illegalAdSubModal" :footer="false" :maskClosable="false" :visible="examineVisible" :confirm-loading="confirmLoading" @cancel="handleCancel" :width="modalWidth">
        <a-row class="row" v-if="goExamineType == 'medical'">
            <img :src="medicalType.imgUrl" />
        </a-row>
        <a-row class="row" v-if="goExamineType == 'health'">
            <img :src="healthType.imgUrl" />
        </a-row>
        <a-row class="row" v-if="goExamineType == 'drug'">
            <img :src="drugType.imgUrl" />
        </a-row>
        <a-row class="row" v-if="goExamineType == 'advertisement'">
            <img v-if="advertisementType.imgUrl != ''" :src="advertisementType.imgUrl" />
        </a-row>
        <a-row class="row">
            <a-button type="primary" class="button" @click="theProcessView()">流程查看</a-button>
            <a-button type="primary" class="button" @click="onLine()">在线办理</a-button>
        </a-row>
    </a-modal>
</template>

<script>
export default {
    props: ["examineVisible", "closeExamineModal", "goExamineType"],
    data() {
        return {
            //医疗器械
            medicalType: {
                imgUrl:'https://resources.xbov.cn/img/hxznggfwpt/medica.png',
                processLink: 'http://zwfw-new.hunan.gov.cn/onething/service/serviceguideck.jsp?approve_id=8A421B4250E42902E053651515ACA404&type=xndtbm&dghy=&cscjwt=',
                onLineLink: 'https://auth.zwfw.hunan.gov.cn/oauth2/authorize?client_id=sXK6HBx3QwuJqaMXqmx2fQ&response_type=gov&redirect_uri=http://zwfw-new.hunan.gov.cn:80/oauth2-login?backUrl=http://zwfw-new.hunan.gov.cn:80/hnvirtualhall/index.jsp' 
            },
            //保健食品
            healthType:{
                imgUrl:'https://resources.xbov.cn/img/hxznggfwpt/heathFood.png',
                processLink: 'http://zwfw-new.hunan.gov.cn/onething/service/serviceguideck.jsp?approve_id=8912ADF039F9E32DE053661515ACAABC&type=xndtbm&dghy=&cscjwt=',
                onLineLink: 'https://auth.zwfw.hunan.gov.cn/oauth2/authorize?client_id=sXK6HBx3QwuJqaMXqmx2fQ&response_type=gov&redirect_uri=http://zwfw-new.hunan.gov.cn:80/oauth2-login?backUrl=http://zwfw-new.hunan.gov.cn:80/hnvirtualhall/index.jsp'
            },
            //药品广告
            drugType:{
                imgUrl:'https://resources.xbov.cn/img/hxznggfwpt/drug.png',
                processLink: 'http://zwfw-new.hunan.gov.cn/onething/service/serviceguideck.jsp?approve_id=8A421B4250E42902E053651515ACA404&type=xndtbm&dghy=&cscjwt=',
                onLineLink: 'https://auth.zwfw.hunan.gov.cn/oauth2/authorize?client_id=sXK6HBx3QwuJqaMXqmx2fQ&response_type=gov&redirect_uri=http://zwfw-new.hunan.gov.cn:80/oauth2-login?backUrl=http://zwfw-new.hunan.gov.cn:80/hnvirtualhall/index.jsp'
            },
            //医疗广告
            advertisementType:{
                imgUrl:'',
                processLink: 'http://zwfw-new.hunan.gov.cn/onething/service/serviceguideck.jsp?approve_id=8A421B4250E42902E053651515ACA404&type=xndtbm&dghy=&cscjwt=',
                onLineLink: 'https://auth.zwfw.hunan.gov.cn/oauth2/authorize?client_id=sXK6HBx3QwuJqaMXqmx2fQ&response_type=gov&redirect_uri=http://zwfw-new.hunan.gov.cn:80/oauth2-login?backUrl=http://zwfw-new.hunan.gov.cn:80/hnvirtualhall/index.jsp'
            },
            confirmLoading: false,
            src: "https://resources.xbov.cn/img/hxznggfwpt/WeChat.jpg",
            modalWidth: "1200px",
        };
    },
    watch:{
        examineVisible(data){
            if (data){
                let domain = this.$store.getters.domainInfo;
                if (domain.domainName == 'bc.zbov.cn'){
                    //河北医疗器械广告
                    this.medicalType.imgUrl = 'https://resources.xbov.cn/img/hxznggfwpt/%E5%8C%BB%E7%96%97%E5%99%A8%E6%A2%B02.jpg'
                    this.medicalType.processLink = 'http://www.hbzwfw.gov.cn/art/2019/11/19/art_21290_7988.html'
                    this.medicalType.onLineLink = 'https://zwfw.hebei.gov.cn/hbjis/front/login.do?uuid=m6ru4qaOaYr3&userType=2&gotoUrl='
                    //河北保健食品广告
                    this.healthType.imgUrl = 'https://resources.xbov.cn/img/hxznggfwpt/%E4%BF%9D%E5%81%A5%E9%A3%9F%E5%93%81%E5%B9%BF%E5%91%8A%E6%B5%81%E7%A8%8B%E5%9B%BE2.jpg';
                    this.healthType.processLink = 'http://www.hbzwfw.gov.cn/art/2019/11/19/art_21290_7599.html'
                    this.healthType.onLineLink = 'https://zwfw.hebei.gov.cn/hbjis/front/login.do?uuid=m6ru4qaOaYr3&userType=2&gotoUrl='
                    //河北药品广告
                    this.drugType.imgUrl = 'https://resources.xbov.cn/img/hxznggfwpt/%E8%8D%AF%E5%93%81%E5%B9%BF%E5%91%8A%E5%AE%A1%E6%89%B92.jpg';
                    this.drugType.processLink = 'http://www.hbzwfw.gov.cn/art/2019/11/20/art_21290_7989.html'
                    this.drugType.onLineLink = 'https://zwfw.hebei.gov.cn/hbjis/front/login.do?uuid=m6ru4qaOaYr3&userType=2&gotoUrl='
                    //河北医疗广告
                    this.advertisementType.imgUrl = 'https://resources.xbov.cn/img/hxznggfwpt/%E5%8C%BB%E7%96%97%E5%B9%BF%E5%91%8A%E5%AE%A1%E6%9F%A52.jpg';
                    this.advertisementType.processLink = 'http://www.hbzwfw.gov.cn/art/2019/11/19/art_21290_7848.html'
                    this.advertisementType.onLineLink = 'https://zwfw.hebei.gov.cn/hbjis/front/login.do?uuid=m6ru4qaOaYr3&userType=2&gotoUrl='
                }
            }
        }
    },
    created() {
        if (document.body.clientWidth > 1920) {
            this.modalWidth = "1500px";
        } else if (document.body.clientWidth > 1600 && document.body.clientWidth <= 1920) {
            this.modalWidth = "1200px";
        } else if (document.body.clientWidth > 1366 && document.body.clientWidth <= 1600) {
            this.modalWidth = "1000px";
        } else if (document.body.clientWidth <= 1366) {
            this.modalWidth = "900px";
        }
    },
    methods: {
        handleCancel() {
            this.closeExamineModal();
        },
        theProcessView() {
            if (this.goExamineType == "medical") {
                window.open(this.medicalType.processLink);
            } else if (this.goExamineType == "health") {
                window.open(this.healthType.processLink);
            } else if (this.goExamineType == "drug") {
                window.open(this.drugType.processLink);  
            }else if(this.goExamineType =="advertisement"){
                window.open(this.advertisementType.processLink);
            }
        },
        onLine() {
            if (this.goExamineType == "medical") {
                window.open(this.medicalType.onLineLink);
            } else if (this.goExamineType == "health") {
                window.open(this.healthType.onLineLink);
            } else if (this.goExamineType == "drug") {
                window.open(this.drugType.onLineLink);
            }else if(this.goExamineType =="advertisement"){
                window.open(this.advertisementType.onLineLink);
            }
        },
    },
};
</script>

<style lang="less" scoped>
.illegalAdSubModal {
    /deep/ .userPhoneSmsInput {
        width: 78%;
    }
    .row {
        margin-top: 10px;
        font-size: 20px;
        text-align: center;
        img {
            width: 1000px;
            height: 600px;
        }
        .button {
            width: 300px;
            height: 50px;
            font-size: 20px;
            margin-left: 50px;
            margin-top: 50px;
            border-radius: 25px;
        }
    }
}
</style>
