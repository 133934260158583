<template>
    <div class="pageContainer">
        <a-row class="financialManagement">
            <a-form-model layout="inline" ref="ruleForm" :model="formInline" @submit="handleSubmit" @submit.native.prevent>
                <a-form-model-item ref="time" prop="time">
                    <a-range-picker v-model="formInline.time" />
                </a-form-model-item>
                <a-form-model-item ref="state" prop="state">
                    <a-select v-model="formInline.state" style="width: 150px" placeholder="支付状态" allowClear>
                        <a-select-option value="0">
                            未支付
                        </a-select-option>
                        <a-select-option value="1">
                            支付成功
                        </a-select-option>
                        <a-select-option value="2">
                            支付中
                        </a-select-option>
                        <a-select-option value="3">
                            已退款
                        </a-select-option>
                        <a-select-option value="4">
                            退款中
                        </a-select-option>
                        <a-select-option value="6">
                            支付失败
                        </a-select-option>
                        <a-select-option value="7">
                            支付取消
                        </a-select-option>
                        <a-select-option value="8">
                            已超时
                        </a-select-option>
                        <a-select-option value="9">
                            退款失败
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item ref="paymentType" prop="paymentType">
                    <a-select v-model="formInline.paymentType" style="width: 150px" placeholder="支付方式" allowClear>
                        <a-select-option value="1">
                            微信
                        </a-select-option>
                        <a-select-option value="2">
                            支付宝
                        </a-select-option>
                        <a-select-option value="3">
                            会员免费
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item class="search">
                    <a-dropdown-button type="primary" html-type="submit">
                        <a-icon type="search" />查询
                        <a-menu slot="overlay">
                            <a-menu-item key="1" @click="reset">重置查询选项</a-menu-item>
                        </a-menu>
                    </a-dropdown-button>
                </a-form-model-item>
            </a-form-model>

            <a-table :columns="columns" :data-source="data" class="table" :loading="tableLoading" :pagination="pagination" @change="tableChange" :scroll="{ y: tableHeight }">
                <span slot="action" slot-scope="text, record" class="action">
                    <!-- {{$moment()+","+$moment(record.activityTrainingVo.activityStartTime)}} -->
                    <a @click="commentsModal(record)" v-if="record.type != 1 && record.state === 1 && record.commentState === 0">去评论</a>
                    <a @click="commentsModal(record)" v-if="record.type != 1 && record.state === 1 && record.commentState === 1">查看评论</a>
                    <a @click="getRefund(record)" v-if="record.type === 1 && record.activityTrainingVo.refundType == 1 && record.state === 1 && record.activityTrainingVo && $moment() < $moment(record.activityTrainingVo.activityStartTime - 172800000)">申请退款</a>
                    <a @click="checkRefund(record)" v-if="record.type === 1 && (record.state === 3 || record.state === 4)">查看退款</a>
                    <a @click="getPay(record)" v-if="(record.type === 1 && record.state === 0 && record.activityTrainingVo && $moment() < $moment(record.activityTrainingVo.activityStartTime - 172800000)) || (record.type !== 1 && record.state === 0)">立即付款</a>
                </span>
            </a-table>
        </a-row>
        <a-modal v-model="dialogShow" title="订单状态已变更" @cancel="dialogHandleOk" :bodyStyle="{ textAlign: 'center' }">
            {{ msg }}
            <template slot="footer">
                <a-button key="submit" type="primary" @click="dialogHandleOk">
                    确定
                </a-button>
            </template>
        </a-modal>

        <!-- 评论弹出框 -->
        <Comments :commentsVisible="commentsVisible" :infoData="infoData" :closeModal="closeModal"></Comments>
        <Refund :refund="refund" :amount="amount" :state="state" :refundCancel="refundCancel"></Refund>
        <PayModal v-if="payData" :changeTradeId="changeTradeId" :payData="payData" :payModalVisible="payModalVisible" :tradeId="tradeId" :closePayModal="closePayModal" />
    </div>
</template>
<script>
import Comments from "./components/Comments.vue";
import Refund from "./components/Refund.vue";
import PayModal from "../IndustryTraining/components/PayModal.vue";
export default {
    components: {
        Comments,
        Refund,
        PayModal,
    },
    data() {
        const _this = this;
        return {
            formInline: {
                time: [],
                state: undefined,
                paymentType: undefined,
            },
            columns: [
                {
                    title: "订单编号",
                    dataIndex: "orderId",
                    key: "orderId",
                    width: '15%',
                },
                {
                    title: "提交人",
                    dataIndex: "userCode",
                    key: "userCode",
                },
                {
                    title: "提交时间",
                    dataIndex: "addTime",
                    key: "addTime",
                    customRender(text) {
                        return _this.$moment(text).format("YYYY-MM-DD HH:mm:ss");
                    },
                },

                {
                    title: "支付方式",
                    dataIndex: "paymentType",
                    key: "paymentType",
                    customRender(text) {
                        return text == 1 ? "微信支付" : text == 2 ? "支付宝支付" : text == 3 ? "会员免费" : "--";
                    },
                },
                {
                    title: "订单类型",
                    dataIndex: "type",
                    key: "type",
                    customRender(text) {
                        return text == 1 ? "报名" : text == 2 ? "谈合作" : text == 3 ? "约见面" : "--";
                    },
                },
                {
                    title: "总金额",
                    dataIndex: "amount",
                    key: "amount",
                    width: 100,
                    customRender(text) {
                        return text ? (text / 1000).toFixed(2) + "元" : "0元";
                    },
                },
                {
                    title: "支付状态",
                    dataIndex: "state",
                    key: "state",
                    customRender(text) {
                        return text == 0 ? "未支付" : text == 1 ? "支付成功" : text == 2 ? "支付中" : text == 3 ? "已退款" : text == 4 ? "退款中" : text == 6 ? "支付失败" : text == 7 ? "支付取消" : text == 8 ? "已超时" : text == 9 ? "已超时" : "--";
                    },
                },
                {
                    title: "能否退款",
                    dataIndex: "refundType",
                    key: "refundType",
                    customRender(text, record) {
                        return record.activityTrainingVo.refundType == 0 ? "不可退款" : record.activityTrainingVo.refundType == 1 ? "可退款" : "--";
                    },
                },
                {
                    title: "操作",
                    dataIndex: "action",
                    key: "action",
                    scopedSlots: { customRender: "action" },
                },
            ],
            data: [],
            pagination: {
                total: 0,
                current: 0,
                defaultPageSize: 10,
                showTotal: (total, range) => `当前第 ${range[0]}-${range[1]} 条 共计 ${total} 条`,
                showSizeChanger: true,
                // pageSizeOptions: ["5", "10", "15", "20"],
                pageSizeOptions: ["5", "10", "15", "20"],
                onShowSizeChange: (current, pageSize) => {
                    this.pageSize = pageSize;
                },
            },
            pageSize: 10,
            userInfo: {},
            tableHeight: 450,
            commentsVisible: false,
            refund: false,
            state: undefined,
            amount: undefined,
            infoData: {},
            tradeId: null,
            payData: null,
            payModalVisible: false,
            tableLoading: true,
            msg: "",
            dialogShow: false,
        };
    },
    created() {
        this.userInfo = this.$store.getters.userInfo;
        this.handleSubmit("placeholder", 0, 10);
        if (document.body.clientWidth <= 1366) {
            this.tableHeight = 390;
        } else if (document.body.clientWidth > 1366 && document.body.clientWidth <= 1440) {
            this.tableHeight = 500;
        } else if (document.body.clientWidth > 1440 && document.body.clientWidth <= 1600) {
            this.tableHeight = 530;
        } else if (document.body.clientWidth > 1600) {
            this.tableHeight = 650;
        }
    },
    methods: {
        changeTradeId(data) {
            this.tradeId = data;
        },
        tableChange(e) {
            this.handleSubmit("placeholder", e.current - 1, e.pageSize);
            this.pagination.current = e.current;
        },

        handleSubmit(e, pageNum, pageSize) {
            let that = this;
            let time = this.formInline.time;
            let params = {
                pageNum: pageNum || pageNum == 0 ? pageNum : 0,
                pageSize: pageSize ? pageSize : that.pageSize ? that.pageSize : 10,
                buyUserCode: this.userInfo.userCode,
            };
            if (time && time.length > 0) {
                let minAddTime = that.$moment(time[0]).format("YYYY-MM-DD") + "T00:00:00";
                let maxAddTime = that.$moment(time[1]).format("YYYY-MM-DD") + "T23:59:59";
                params.minAddTime = minAddTime;
                params.maxAddTime = maxAddTime;
            }
            if (this.formInline.paymentType) {
                params.paymentType = Number(this.formInline.paymentType);
            }
            if (this.formInline.state) {
                params.state = Number(this.formInline.state);
            }
            this.tableLoading = true;
            this.$http
                .post("/resource/trainorder/queryPageList", params)
                .then((res) => {
                    if (res.rescode == 200) {
                        this.data = res.data.dataList;
                        this.pagination.total = res.data.totalCount;
                    } else {
                        this.$message.warning(res.msg);
                    }
                    this.tableLoading = false;
                })
                .catch((error) => {
                    this.tableLoading = false;
                    console.log(error);
                });
        },
        reset() {
            this.$refs.ruleForm.resetFields();
            this.handleSubmit("placeholder", 0, this.pageSize);
            this.pagination.current = 0;
        },
        makeOutAnInvoice() {
            this.$router.push({
                name: "MakeOutAnInvoice",
                query: {},
            });
        },
        invoiceRecord() {
            this.$router.push({
                name: "InvoiceRecord",
                query: {},
            });
        },
        // 评论框
        commentsModal(record) {
            this.commentsVisible = true;
            this.infoData = {
                orderId: record.orderId,
                commentState: record.commentState,
                userTalentVo: record.userTalentVo,
            };
        },
        getRefund(record) {
            // console.log('getRefund',record.paymentType,record.orderId,record.amount/1000,);
            let url = "";
            if (record.paymentType === 1) {
                url = "/resource/pay/refundwxpay";
            } else if (record.paymentType === 2) {
                url = "/resource/pay/aliRefund";
            }
            let params = {
                activityOrderId: record.orderId,
            };
            this.tableLoading = true;
            this.$http
                .post(url, params)
                .then((res) => {
                    if (res.rescode == 200) {
                        this.$message.success("退款申请成功");
                    } else {
                        this.$message.warning(res.msg);
                    }
                    this.tableLoading = false;
                })
                .catch((error) => {
                    this.tableLoading = false;
                    console.log(error);
                    this.$message.warning(error.msg);
                });
            // this.amount=record.amount/1000;
        },
        checkRefund(record) {
            this.refund = true;
            this.amount = record.amount / 1000;
            this.state = record.state;
        },
        getPay(record) {
            let that = this;
            if (record.type === 1) {
                // 重新查价，避免优惠名额已被抢占；
                this.$http
                    .post("/resource/activitytraining/activityCosting?activityId=" + record.activityId + "&applicantsNumber=" + record.peopleNumber)
                    .then((res) => {
                        console.log("activitytraining", res);
                        if (res.rescode === 200) {
                            if (res.data.price && res.data.price === record.amount / 1000) {
                                that.payData = {
                                    amount: record.amount,
                                };
                                that.tradeId = record.orderId;
                                setTimeout(() => {
                                    that.payModalVisible = true;
                                }, 500);
                            } else {
                                that.msg = "优惠名额已满，订单价格已更新";
                                that.trainorderUpdate(record, res.data.price, that);
                            }
                        } else if (res.rescode === 201) {
                            that.msg = "活动名额不足";
                            that.trainorderUpdate(record, undefined, that, "close");
                        }
                    })
                    .catch((err) => {
                        console.log("activitytraining err", err);
                        this.$message.warning(error.msg);
                    });
            } else {
                this.payData = {
                    amount: record.amount,
                };
                this.tradeId = record.orderId;
                setTimeout(() => {
                    this.payModalVisible = true;
                }, 500);
            }
        },
        trainorderUpdate(item, amount, that, type) {
            let params = {};
            if (type === "close") {
                params = {
                    orderId: item.orderId,
                    state: 6,
                };
            } else {
                params = {
                    orderId: item.orderId,
                    amount: amount * 1000,
                };
            }
            this.$http
                .post("/resource/trainorder/update", params)
                .then((res) => {
                    console.log("trainorder", res);
                    if (res.data.rescode === 201) {
                        that.$message.warning(res.msg);
                    }
                    that.dialogShow = true;
                })
                .catch((err) => {
                    console.log("trainorder err", err);
                    that.dialogShow = true;
                    that.$message.warning(err.msg);
                    wx.hideLoading();
                });
        },
        dialogHandleOk() {
            this.dialogShow = false;
            this.handleSubmit("placeholder", 0, 10);
        },
        handleOk(e) {
            this.confirmLoading = true;
            setTimeout(() => {
                this.commentsVisible = false;
                this.confirmLoading = false;
            }, 2000);
        },
        handleCancel(e) {
            console.log("Clicked cancel button");
            this.commentsVisible = false;
        },
        closeModal() {
            this.commentsVisible = false;
        },
        refundCancel() {
            this.refund = false;
        },
        closePayModal() {
            this.payModalVisible = false;
            this.handleSubmit("placeholder", 0, 10);
            // this.$router.push({ name: "OrderCenter" });
        },
    },
};
</script>
<style lang="less" scoped>
.pageContainer {
    .financialManagement {
        padding: 16px;
        height: 100%;
        background: #fff;
        .button {
            margin-top: 3px;
            margin-right: 20px;
            color: #999999;
        }
        .search {
            float: right;
        }
    }
    .action {
        a {
            margin: 0 10px;
        }
    }
}
</style>
