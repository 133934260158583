<template>
    <div class="pageContainer">
        <div class="checkOrder">
            <a-spin :spinning="spinning">
                <a-form-model layout="inline" ref="discountForm" :model="formInline" @submit="handleSubmit" @submit.native.prevent>
                    <a-form-model-item ref="couponType" prop="couponType">
                        <a-select class="selectDom" v-model="formInline.couponType" placeholder="请选择优惠券状态" allowClear>
                            <a-select-option value="0">
                                未使用
                            </a-select-option>
                            <a-select-option value="1">
                                已使用
                            </a-select-option>
                            <a-select-option value="2">
                                已过期
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item class="search">
                        <a-dropdown-button type="primary" html-type="submit">
                            <a-icon type="search" />查询
                            <a-menu slot="overlay">
                                <a-menu-item key="1" @click="reset">重置查询选项</a-menu-item>
                            </a-menu>
                        </a-dropdown-button>
                    </a-form-model-item>
                </a-form-model>
                <a-table :columns="columns" :data-source="discountData" :scroll="{ y: tableHeight }" :loading="loading" :pagination="pagination" @change="discountChange">

                </a-table>
            </a-spin>
        </div>
    </div>
</template>
<script>

export default {
    data(){
        const _this = this;
        return{
            tableHeight:450,
            spinning:false,
            discountData:[],
            formInline:{
                couponType:undefined,
            },
            columns:[
                {
                    title:"优惠券名称",
                    dataIndex:"couponName",
                    key:"couponName",
                    scopedSlots:{ customRender:"couponName"},
                },
                {
                    title:"领取时间",
                    dataIndex:"addTime",
                    key:"addTime",
                    scopedSlots:{ customRender:"addTime"},
                },
                {
                    title:"优惠内容",
                    dataIndex:"",
                    key:"",
                    // scopedSlots:{ customRender:""},
                    customRender(text){
                        return "图片/视频/音频/文本 "
                    }
                },
                {
                    title:"有效时间",
                    dataIndex:"expirationTime",
                    key:"expirationTime",
                    scopedSlots:{ customRender:"expirationTime"},
                },
                {
                    title:"状态",
                    dataIndex:"couponType",
                    key:"couponType",
                    customRender(text) {
                        return text == 0 ? "未使用" : text == 1 ? "已使用" : text == 2 ? "已过期" : "--";
                    },
                },
            ],
            pagination: {
                total: 0,
                current: 0,
                defaultPageSize: 10,
                showTotal: (total, range) => `当前第 ${range[0]}-${range[1]} 条 共计 ${total} 条`,
                showSizeChanger: true,
                pageSizeOptions: ["5", "10", "15", "20"],
                onShowSizeChange: (current, pageSize) => {
                    this.pageSize = pageSize;
                },
            },
        }
    },
    created(){ 
        this.getDiscount(0,10);
    },
    methods:{
        //获取优惠券列表
        getDiscount(pageNum,pageSize){
            this.pagination.current = pageNum;
            this.pagination.pageSize = pageSize;
            const _this = this;
            let params = {
                pageNum: pageNum,
                pageSize: pageSize,
            };
            _this.loading = true;
            if(this.formInline.couponType && this.formInline.couponType !=""){
                params.couponType = this.formInline.couponType;
            }
            _this.$http
                .post("/usercoupon/queryPageList", params)
                .then((res) => {
                    _this.loading = false;
                    _this.discountData = res.data.dataList;
                    _this.pagination.total = res.data.totalCount;
                })
                .catch((err) => {
                    _this.loading = false;
                });
        },
        discountChange(e) {
            this.getDiscount(e.current - 1, e.pageSize,1);
            this.pagination.current = e.current;
        },
        //查询
        reset() {
            this.$refs.discountForm.resetFields();
            this.getDiscount(0, 10);
        },
        handleSubmit() {
            this.getDiscount(0, 10);
        },
    },
}

</script>
<style lang="less" scoped>
.pageContainer {
    .checkOrder {
        padding: 16px;
        height: 100%;
        background: #fff;
    }
    .selectDom {
        width: 150px;
    }
    .search{
        float: right;
    }
}
</style>