<template>
    <div class="pageContainer">
        <div class="container">
            <a-tabs default-active-key="1" @change="callback">
                <!-- 公司管理 -->
                <a-tab-pane key="1" tab="公司管理">
                    <a-button type="primary" @click="creCompany()">公司信息创建</a-button>
                    <a-table :columns="Company" :data-source="companyData" :scroll="{ y: tableHeight }" :loading="loading" :pagination="pagination" @change="companyChange">
                        <span slot="logoUrl" slot-scope="text, record">
                            <div class="imgBox">
                                <img :src="baseImgUrl + record.logoUrl" class="imgSize" />
                            </div>
                        </span>
                        <span slot="addTime" slot-scope="text">
                            {{ getAddTime(text) }}
                        </span>
                        <span slot="province" slot-scope="text, record">
                            {{ record.province + record.city + record.district }}
                        </span>
                        <span slot="action" slot-scope="text, record">
                            <a @click="companyView(record)">编辑</a>
                            <a-divider type="vertical"></a-divider>
                            <a-popconfirm title="确认删除吗？" ok-text="确认" cancel-text="取消" @confirm="delCompany(record)">
                                <a>删除</a>
                            </a-popconfirm>
                        </span>
                    </a-table>
                </a-tab-pane>
                <!-- 客户管理 -->
                <a-tab-pane key="2" tab="客户管理">
                    <a-button type="primary" @click="creClient()">客户信息创建</a-button>
                    <a-table :columns="client" :data-source="clientData" :scroll="{ y: tableHeight }" :loading="loading" :pagination="pagination" @change="clientChange">
                        <span slot="logoUrl" slot-scope="text, record">
                            <div class="imgBox">
                                <img :src="baseImgUrl + record.logoUrl" class="imgSize" />
                            </div>
                        </span>
                        <span slot="addTime" slot-scope="text">
                            {{ getAddTime(text) }}
                        </span>
                        <span slot="action" slot-scope="text, record">
                            <a @click="clientView(record)">编辑</a>
                            <a-divider type="vertical"></a-divider>
                            <a-popconfirm title="确认删除吗？" ok-text="确认" cancel-text="取消" @confirm="delClient(record)">
                                <a>删除</a>
                            </a-popconfirm>
                        </span>
                    </a-table>
                </a-tab-pane>
                <!-- 媒介管理 -->
                <a-tab-pane key="3" tab="媒介管理">
                    <a-button type="primary" @click="creMedium()">媒介信息创建</a-button>
                    <a-table :columns="Medium" :data-source="mediumData" :scroll="{ y: tableHeight }" :loading="loading" :pagination="pagination" @change="MediumChange">
                        <span slot="imagesUrl" slot-scope="text, record">
                            <div class="imgBox">
                                <img :src="baseImgUrl + record.imagesUrl[0]" class="imgSize" />
                            </div>
                        </span>
                        <span slot="action" slot-scope="text, record">
                            <a @click="mediumView(record)">编辑</a>
                            <a-divider type="vertical"></a-divider>
                            <a-popconfirm title="确认删除吗？" ok-text="确认" cancel-text="取消" @confirm="delMedium(record)">
                                <a>删除</a>
                            </a-popconfirm>
                        </span>
                    </a-table>
                </a-tab-pane>
                <!-- 投放管理 -->
                <a-tab-pane key="4" tab="投放管理">
                    <a-button type="primary" @click="crePutIn()">投放信息创建</a-button>
                    <a-table :columns="PutIn" :data-source="putInData" :scroll="{ y: tableHeight }" :loading="loading" :pagination="pagination" @change="PutInChange">
                        <span slot="action" slot-scope="text, record">
                            <a @click="putInView(record)">编辑</a>
                            <a-divider type="vertical"></a-divider>
                            <a-popconfirm title="确认删除吗？" ok-text="确认" cancel-text="取消" @confirm="delPutIn(record)">
                                <a>删除</a>
                            </a-popconfirm>
                        </span>
                    </a-table>
                </a-tab-pane>
                <!-- 报告管理 -->
                <a-tab-pane key="5" tab="报告管理">
                    <a-table :columns="Report" :data-source="reportData" :scroll="{ y: tableHeight }" :loading="loading" :pagination="pagination" @change="ReportChange">
                        <span slot="pdfBuildState" slot-scope="text">
                            {{ text == 0 ? "未生成" : text == 1 ? "已生成" : "生成中" }}
                        </span>
                        <span slot="action" slot-scope="text, record">
                            <a v-if="record.pdfBuildState == 1" :href="baseImgUrl + record.pdfUrl" target="_blank">下载</a>
                            <a-divider v-if="record.pdfBuildState == 1" type="vertical"></a-divider>
                            <a-popconfirm title="确认删除吗？" ok-text="确认" cancel-text="取消" @confirm="delReport(record)">
                                <a>删除</a>
                            </a-popconfirm>
                        </span>
                    </a-table>
                </a-tab-pane>
                <!-- 关联报告 -->
                <a-tab-pane key="6" tab="关联报告">
                    <a-table :columns="Relevance" :data-source="relevanceData" :scroll="{ y: tableHeight }" :loading="loading" :pagination="pagination" @change="RelevanceChange">
                        <span slot="pdfBuildState" slot-scope="text">
                            {{ text == 0 ? "未生成" : text == 1 ? "已生成" : "生成中" }}
                        </span>
                        <span slot="action" slot-scope="text, record">
                            <a v-if="record.pdfBuildState == 1" :href="baseImgUrl + record.pdfUrl" target="_blank">下载</a>
                        </span>
                    </a-table>
                </a-tab-pane>
            </a-tabs>
        </div>
        <!-- 弹出框 -->
        <AddCompany :addCompany="addCompany" :closeAddCompany="closeAddCompany" :opCompany="opCompany"></AddCompany>
        <AddClient :addClient="addClient" :closeAddClient="closeAddClient" :opClient="opClient"></AddClient>
        <AddMedium :addMedium="addMedium" :closeAddMedium="closeAddMedium" :opMedium="opMedium"></AddMedium>
        <AddPutIn :addPutIn="addPutIn" :closeAddPutIn="closeAddPutIn" :opPutIn="opPutIn"></AddPutIn>
    </div>
</template>
<script>
import AddPutIn from "./components/AddPutIn";
import AddCompany from "./components/AddCompany";
import AddClient from "./components/AddClient";
import AddMedium from "./components/AddMedium";

export default {
    data() {
        return {
            data: [],
            companyData: [],
            clientData: [],
            mediumData: [],
            putInData: [],
            reportData: [],
            relevanceData:[],
            addCompany: false,
            addClient: false,
            addMedium: false,
            addPutIn: false,
            tableHeight:450,
            baseImgUrl: process.env.VUE_APP_RESOURCES_HOST,
            pdfUrl: "",
            //公司管理列表
            Company: [
                {
                    title: "公司LOGO",
                    dataIndex: "logoUrl",
                    key: "logoUrl",
                    scopedSlots: { customRender: "logoUrl" },
                },
                {
                    title: "公司名称",
                    dataIndex: "name",
                    key: "name",
                    scopedSlots: { customRender: "name" },
                },
                {
                    title: "联系人",
                    dataIndex: "contactName",
                    key: "contactName",
                    scopedSlots: { customRender: "contactName" },
                },
                {
                    title: "电话号码",
                    dataIndex: "contactPhone",
                    key: "contactPhone",
                    scopedSlots: { customRender: "contactPhone" },
                },
                {
                    title: "区域",
                    dataIndex: "province",
                    key: "province",
                    scopedSlots: { customRender: "province" },
                },
                {
                    title: "添加时间",
                    dataIndex: "addTime",
                    key: "addTime",
                    scopedSlots: { customRender: "addTime" },
                },
                {
                    title: "操作",
                    dataIndex: "action",
                    width: 120,
                    scopedSlots: { customRender: "action" },
                },
            ],
            //客户管理列表
            client: [
                {
                    title: "图片",
                    dataIndex: "logoUrl",
                    ket: "logoUrl",
                    scopedSlots: { customRender: "logoUrl" },
                },
                {
                    title: "客户名称",
                    dataIndex: "name",
                    ket: "name",
                    scopedSlots: { customRender: "name" },
                },
                {
                    title: "联系人",
                    dataIndex: "contactName",
                    ket: "contactName",
                    scopedSlots: { customRender: "contactName" },
                },
                {
                    title: "电话号码",
                    dataIndex: "contactPhone",
                    ket: "contactPhone",
                    scopedSlots: { customRender: "contactPhone" },
                },
                {
                    title: "添加时间",
                    dataIndex: "addTime",
                    key: "addTime",
                    scopedSlots: { customRender: "addTime" },
                },
                {
                    title: "操作",
                    dataIndex: "action",
                    width: 120,
                    scopedSlots: { customRender: "action" },
                },
            ],
            //媒介管理列表
            Medium: [
                {
                    title: "图片",
                    dataIndex: "imagesUrl",
                    ket: "imagesUrl",
                    scopedSlots: { customRender: "imagesUrl" },
                },
                {
                    title: "媒介名称",
                    dataIndex: "name",
                    ket: "name",
                    scopedSlots: { customRender: "name" },
                },
                {
                    title: "媒介类型",
                    dataIndex: "type",
                    ket: "type",
                    scopedSlots: { customRender: "type" },
                },
                {
                    title: "媒介尺寸",
                    dataIndex: "size",
                    ket: "size",
                    scopedSlots: { customRender: "size" },
                },
                {
                    title: "操作",
                    dataIndex: "action",
                    scopedSlots: { customRender: "action" },
                },
            ],
            //投放管理列表
            PutIn: [
                {
                    title: "投放名称",
                    dataIndex: "name",
                    ket: "name",
                    scopedSlots: { customRender: "name" },
                },
                {
                    title: "添加日期",
                    dataIndex: "addTime",
                    ket: "addTime",
                    scopedSlots: { customRender: "addTime" },
                },
                {
                    title: "媒介点位个数",
                    dataIndex: "mediaCount",
                    ket: "mediaCount",
                    scopedSlots: { customRender: "mediaCount" },
                },
                {
                    title: "操作",
                    dataIndex: "action",
                    scopedSlots: { customRender: "action" },
                },
            ],
            //报告管理列表
            Report: [
                {
                    title: "报告ID",
                    dataIndex: "id",
                    key:"id",
                    scopedSlots: { customRender: "id" },
                },
                {
                    title:"公司名称",
                    dataIndex:"companyName",
                    key:"companyName",
                    scopedSlots: { customRender: "companyName" },
                },
                {
                    title: "客户名称",
                    dataIndex: "customerName",
                    key:"customerName",
                    scopedSlots: { customRender: "customerName" },
                },
                {
                    title: "投放名称",
                    dataIndex: "releaseName",
                    key:"releaseName",
                    scopedSlots: { customRender: "releaseName" },
                },
                {
                    title: "生成状态",
                    dataIndex: "pdfBuildState",
                    key:"pdfBuildState",
                    scopedSlots: { customRender: "pdfBuildState" },
                },
                {
                    title: "操作",
                    dataIndex: "action",
                    scopedSlots: { customRender: "action" },
                },
            ],
            //关联报告
            Relevance: [
                {
                    title: "报告ID",
                    dataIndex: "id",
                    key:"id",
                    scopedSlots: { customRender: "id" },
                },
                {
                    title:"公司名称",
                    dataIndex:"companyName",
                    key:"companyName",
                    scopedSlots: { customRender: "companyName" },
                },
                {
                    title: "客户名称",
                    dataIndex: "customerName",
                    key:"customerName",
                    scopedSlots: { customRender: "customerName" },
                },
                {
                    title: "投放名称",
                    dataIndex: "releaseName",
                    key:"releaseName",
                    scopedSlots: { customRender: "releaseName" },
                },
                {
                    title: "生成状态",
                    dataIndex: "pdfBuildState",
                    key:"pdfBuildState",
                    scopedSlots: { customRender: "pdfBuildState" },
                },
                {
                    title: "操作",
                    dataIndex: "action",
                    scopedSlots: { customRender: "action" },
                },
            ],
            pagination: {
                total: 0,
                current: 0,
                defaultPageSize: 10,
                showTotal: (total, range) => `当前第 ${range[0]}-${range[1]} 条 共计 ${total} 条`,
                showSizeChanger: true,
                pageSizeOptions: ["5", "10", "15", "20"],
                onShowSizeChange: (current, pageSize) => {
                    this.pageSize = pageSize;
                },
            },
            opCompany: "add",
            opClient: "add",
            opMedium: "add",
            opPutIn: "add",
            loading: true,
            activeTabKey: 1,
        };
    },
    created() {
        this.userInfo = this.$store.getters.userInfo;
        this.getCompany(0, 10);
    },
    components: {
        AddCompany,
        AddClient,
        AddMedium,
        AddPutIn,
    },
    methods: {
        getAddTime(date) {
            return this.$moment(date).format("YYYY-MM-DD HH:mm:ss");
        },
        callback(key) {
            console.log(key);
            //报告管理接口调用
            if (key == 5) {
                this.getReport(0, 10);
            } else if (key == 4) {
                this.getPutIn(0, 10);
            } else if (key == 3) {
                this.getMedium(0, 10);
            } else if (key == 2) {
                this.getClient(0, 10);
            } else if (key == 1) {
                this.getCompany(0, 10);
            }else if(key == 6){
                this.getRelevance(0,10);
            }
            this.activeTabKey = key;
            this.loading = true;
        },
        //公司管理编辑
        creCompany() {
            this.opCompany = "add";
            this.addCompany = true;
        },
        //查看公司信息
        companyView(record) {
            this.opCompany = record.id;
            this.addCompany = true;
        },
        //公司列表切换
        companyChange(e) {
            this.getCompany(e.current - 1, e.pageSize);
            this.pagination.current = e.current;
        },
        //获取公司管理信息
        getCompany(pageNum, pageSize) {
            this.pagination.current = pageNum;
            this.pagination.pageSize = pageSize;
            const _this = this;
            let params = {
                pageNum: pageNum,
                pageSize: pageSize,
            };
            _this.loading = true;
            _this.$http
                .post("/publishcompany/queryPageList", params)
                .then((res) => {
                    _this.loading = false;
                    _this.companyData = res.data.dataList;
                    _this.pagination.total = res.data.totalCount;
                })
                .catch((err) => {
                    _this.loading = false;
                });
        },
        //公司管理关闭
        closeAddCompany() {
            this.addCompany = false;
            this.opCompany = "add";
            this.loading = true;
            this.getCompany(0, 10);
        },
        //公司管理删除
        delCompany(record) {
            let _this = this;

            let params = {
                ids: [record.id],
            };
            _this.$http
                .post("/publishcompany/delete", params)
                .then((res) => {
                    if (res.rescode == 200) {
                        _this.$message.success("删除成功");
                        this.getCompany(0, 10);
                    }else{
                        _this.$message.error(res.msg);
                    }
                })
                .catch((err) => {
                    _this.$message.error(res.msg);
                });
        },

        //客户信息创建
        creClient() {
            this.opClient = "add";
            this.addClient = true;
        },
        //客户信息查看
        clientView(record) {
            this.opClient = record.id;
            this.addClient = true;
        },
        //客户列表切换
        clientChange(e) {
            this.getClient(e.current - 1, e.pageSize);
            this.pagination.current = e.current;
        },
        //获取客户信息
        getClient(pageNum, pageSize) {
            this.pagination.current = pageNum;
            this.pagination.pageSize = pageSize;
            const _this = this;
            let params = {
                pageNum: pageNum,
                pageSize: pageSize,
            };
            _this.loading = true;
            _this.$http
                .post("/publishcustomer/queryPageList", params)
                .then((res) => {
                    _this.loading = false;
                    _this.clientData = res.data.dataList;
                    _this.pagination.total = res.data.totalCount;
                })
                .catch((err) => {
                    _this.loading = false;
                });
        },
        //客户信息关闭
        closeAddClient() {
            this.addClient = false;
            this.opClient = "add";
            this.loading = true;
            this.getClient(0, 10);
        },
        //客户管理删除
        delClient(record) {
            let _this = this;
            let params = {
                idList: [record.id],
            };
            _this.$http
                .post("/publishcustomer/delete", params)
                .then((res) => {
                    if (res.rescode == 200) {
                    _this.$message.success("删除成功");
                    this.getClient(0, 10);
                    }else{
                        _this.$message.error(res.msg);
                    }
                })
                .catch((err) => {
                    _this.$message.error(res.msg);
                });
        },

        //媒介信息创建
        creMedium() {
            this.opMedium = "add";
            this.addMedium = true;
        },
        //媒介信息查看
        mediumView(record) {
            this.opMedium = record.id;
            this.addMedium = true;
        },
        //媒介列表切换
        MediumChange(e) {
            this.getMedium(e.current - 1, e.pageSize);
            this.pagination.current = e.current;
        },
        //获取媒介信息
        getMedium(pageNum, pageSize) {
            this.pagination.current = pageNum;
            this.pagination.pageSize = pageSize;
            const _this = this;
            let params = {
                pageNum: pageNum,
                pageSize: pageSize,
            };
            _this.loading = true;
            _this.$http
                .post("/publishmedia/queryPageList", params)
                .then((res) => {
                    _this.loading = false;
                    _this.mediumData = res.data.dataList;
                    _this.pagination.total = res.data.totalCount;
                })
                .catch((err) => {
                    _this.loading = false;
                });
        },
        //媒介管理关闭
        closeAddMedium() {
            this.addMedium = false;
            this.opMedium = "add";
            this.getMedium(0, 10);
        },
        //媒介管理删除
        delMedium(record) {
            let _this = this;
            let params = {
                idList: [record.id],
            };
            _this.$http
                .post("/publishmedia/delete", params)
                .then((res) => {
                    if (res.rescode == 200) {
                        _this.$message.success("删除成功");
                        this.getMedium(0, 10);
                    }else{
                         _this.$message.error(res.msg);
                    }
                })
                .catch((err) => {    
                    _this.$message.error(res.msg);
                });
        },

        //投放信息创建
        crePutIn() {
            this.opPutIn = "add";
            this.addPutIn = true;
        },
        //投放信息查看
        putInView(record) {
            this.opPutIn = record.id;
            this.addPutIn = true;
        },
        //投放列表切换
        PutInChange(e) {
            this.getPutIn(e.current - 1, e.pageSize);
            this.pagination.current = e.current;
        },
        //获取投放列表信息
        getPutIn(pageNum, pageSize) {
            this.pagination.current = pageNum;
            this.pagination.pageSize = pageSize;
            const _this = this;
            let params = {
                pageNum: pageNum,
                pageSize: pageSize,
            };
            _this.loading = true;
            _this.$http
                .post("/publishrelease/queryPageList", params)
                .then((res) => {
                    _this.loading = false;
                    _this.putInData = res.data.dataList;
                    this.putInData.map((item) =>{
                        item.addTime = this.$moment(item.addTime.addtime).format("YYYY-MM-DD");
                    });
                    _this.pagination.total = res.data.totalCount;
                })
                .catch((err) => {
                    _this.loading = false;
                });
        },
        //投放管理关闭
        closeAddPutIn() {
            this.addPutIn = false;
            this.opPutIn = "add";
            this.getPutIn(0, 10);
        },
        //投放管理删除
        delPutIn(record) {
            let _this = this;
            let params = {
                idList: [record.id],
            };
            _this.$http
                .post("/publishrelease/delete", params)
                .then((res) => {
                    if (res.rescode == 200) {
                    _this.$message.success("删除成功");
                    this.getPutIn(0, 10);
                    }else{
                        _this.$message.error(res.msg);
                    }
                })
                .catch((err) => {   
                    _this.$message.error(res.msg);
                });
        },
        //报告列表切换
        ReportChange(e) {
            this.getReport(e.current - 1, e.pageSize);
            this.pagination.current = e.current;
        },
        //获取报告管理信息
        getReport(pageNum, pageSize) {
            this.pagination.current = pageNum;
            this.pagination.pageSize = pageSize;
            const _this = this;
            let params = {
                pageNum: pageNum,
                pageSize: pageSize,
            };
            _this.loading = true;
            _this.$http
                .post("/publishcollectjob/queryPageList", params)
                .then((res) => {
                    _this.loading = false;
                    _this.reportData = res.data.dataList;
                    _this.pagination.total = res.data.totalCount;
                })
                .catch((err) => {
                    _this.loading = false;
                });
        },
        //报告管理删除
        delReport(record) {
            let _this = this;
            _this.$http
                .post("/publishcollectjob/delete?id=" + record.id)
                .then((res) => {
                    if (res.rescode == 200) {
                    _this.$message.success("删除成功");
                    this.getReport(0, 10);
                    }else{
                        _this.$message.error(res.msg);
                    }
                })
                .catch((err) => {
                    _this.$message.error(res.msg);
                });
        },
        //获取关联报告列表
        getRelevance(pageNum, pageSize){
            this.pagination.current = pageNum;
            this.pagination.pageSize = pageSize;
            const _this = this;
            let params = {
                pageNum: pageNum,
                pageSize: pageSize,
            };
            _this.loading = true;
            _this.$http
                .post("/publishcollectjob/linkedReports", params)
                .then((res) => {
                    _this.loading = false;
                    _this.relevanceData = res.data.dataList;
                    _this.pagination.total = res.data.totalCount;
                })
                .catch((err) => {
                    _this.loading = false;
                });
        },
        //关联报告列表切换
        RelevanceChange(e) {
            this.getRelevance(e.current - 1, e.pageSize);
            this.pagination.current = e.current;
        },
    },
};
</script>
<style lang="less" scoped>
.pageContainer {
    .container {
        background: #fff;
        height: 100%;
        overflow: auto;
        .ant-divider {
            margin: 0 8px;
        }
        .ant-tabs-tabpane {
            padding: 0 8px;
        }
        .imgBox {
            width: 40px;
            height: 40px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
