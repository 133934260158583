<template>
    <a-modal title="评论" :visible="commentsVisible" @ok="handleOk" @cancel="handleCancel" :maskClosable="false" :footer="null">
        <a-row style="padding:20px 60px">
            <a-row>
                <a-col :span="4" v-if="infoData.userTalentVo && infoData.userTalentVo.photoUrl">
                    <a-avatar :src="baseUrl + infoData.userTalentVo.photoUrl" />
                </a-col>
                <a-col :span="14">
                    <div>
                        <span>{{ infoData.userTalentVo.name }}</span
                        >&nbsp;&nbsp;
                        <span>{{ title }}</span>
                    </div>
                    <div>
                        <span>擅长:</span>
                        <span>{{ infoData.userTalentVo.areasExpertise }}</span>
                    </div>
                </a-col>
            </a-row>
            <a-row style="marginTop:10px">
                <div v-if="infoData.commentState === 1" class="textareaBox">
                    {{ textarea }}
                </div>
                <a-textarea placeholder="请输入评价内容" v-model="textarea" v-else :rows="4" />
            </a-row>
            <a-row style="margin:30px 0">
                <span>请对此次咨询进行评分：</span>
                <a-rate v-model="rate" :disabled="infoData.commentState === 1" />
            </a-row>
            <a-button type="primary" @click="submit" v-if="infoData.commentState === 0" class="commentsBtn">
                发表评价
            </a-button>
        </a-row>
    </a-modal>
</template>

<script>
export default {
    props: ["commentsVisible", "closeModal", "infoData"],
    data() {
        return {
            value: 0,
            title: "--",
            rate: 0,
            textarea: "",
            baseUrl: process.env.VUE_APP_RESOURCES_HOST,
            loading: false,
        };
    },
    mounted() {},
    watch: {
        commentsVisible() {
            let title = "";
            if (this.infoData && this.infoData.userTalentVo) {
                if (this.infoData.userTalentVo.level === 1) {
                    title += "初级";
                } else if (this.infoData.userTalentVo.level === 2) {
                    title += "中级";
                } else if (this.infoData.userTalentVo.level === 3) {
                    title += "高级";
                }
                if (this.infoData.userTalentVo.type === 1) {
                    title += "人才";
                } else if (this.infoData.userTalentVo.type === 2) {
                    title += "专家";
                } else if (this.infoData.userTalentVo.type === 3) {
                    title += "律师";
                }
            }
            this.title = title;
            this.trainorderreviewQuery();
        },
    },
    methods: {
        handleCancel() {
            this.closeModal();
        },
        handleOk() {},
        submit() {
            let _this = this;
            let rate = this.rate;
            let textarea = this.textarea;
            if (rate < 1) {
                this.$message.warning("请评分后发表");
            } else {
                let params = {
                    totalScore: rate,
                    content: textarea,
                    userTalentId: this.infoData.userTalentVo.flowId,
                    orderId: this.infoData.orderId,
                };
                this.loading = true;
                this.$http
                    .post("/resource/trainorderreview/add", params)
                    .then((res) => {
                        if (res.rescode == 200) {
                            _this.$message.success("评论成功");
                        } else {
                            _this.$message.warning(res.msg);
                        }
                        _this.loading = false;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
            this.closeModal();
        },
        //  查询评论
        trainorderreviewQuery() {
            let that = this;
            this.$http
                .get("/resource/trainorderreview/query?orderId=" + that.infoData.orderId)
                .then((res) => {
                    that.rate = res.data.totalScore;
                    that.textarea = res.data.content;
                })
                .catch((err) => {
                    console.log("trainorder err", err);
                });
        },
    },
};
</script>

<style lang="less" scoped>
.commentsBtn {
    width: 100%;
    height: 50px;
    border-radius: 30px;
}
.textareaBox {
    height: 200px;
    overflow: auto;
    background-color: #f8f8f8;
    border-radius: 10px;
    padding: 10px;
}
</style>
