<template>
	<div class="pageContainer">
		<a-row class="financialManagement">
			<a-spin :spinning="pageLoading">
				<a-breadcrumb>
					<a-breadcrumb-item><a @click="getTalentPool">专家人才</a></a-breadcrumb-item>
					<a-breadcrumb-item>{{ details.type == 1 ? '人才' : '专家' }}</a-breadcrumb-item>
				</a-breadcrumb>
				<a-row class="titleRow">
					<span class="longString"></span>
					个人信息
				</a-row>
				<a-row class="details">
					<a-col :span="2" :offset="3">
						<img :src="baseImgUrl + details.photoUrl" width="90%" height="100px" />
						<div class="share">
							<span>分享：</span>
							<a-button icon="link" @click="copy()" />
						</div>
					</a-col>
					<a-col :span="12" class="row-center">
						<a-row class="row">
							<a-col :span="12">
								<span>{{ details.name }}</span>
								&nbsp;&nbsp;
								<span>{{ details.level == 1 ? '初级' : details.level == 2 ? '中级' : details.level == 3 ? '高级' : '' }}</span>
								<span>{{ details.type == 1 ? '人才' : details.type == 2 ? '专家' : details.type == 3 ? '律师' : '' }}</span>
							</a-col>
							<a-col :span="12">
								<!-- &nbsp;&nbsp;&nbsp;&nbsp; -->
								<span>擅长:</span>
								<span>{{ details.areasExpertise }}</span>
							</a-col>
						</a-row>
						<a-row class="row">
							<a-col :span="12">
								<span>{{ details.type == 1 ? '合作人数：' : '约见人数：' }}</span>
								<!-- &nbsp;&nbsp; -->
								<span>{{ details.contactSum }}人</span>
							</a-col>
							<a-col :span="12">
								<!-- &nbsp;&nbsp;&nbsp;&nbsp; -->
								<span>粉丝用户：</span>
								<span>{{ details.fansNumber == null ? 0 : details.fansNumber }}人</span>
							</a-col>
						</a-row>
						<a-row class="row">
							<!-- <a-col :span="12">
								<span>专家编号：</span>
								<span>{{ details.flowId == null ? '--' : details.flowId }}</span>
							</a-col> -->
							<a-col :span="12">
								<span>{{ type == 1 ? '人才个人案例：' : '专家个人案例：' }}</span>
								<!-- <a>{{ details.caseLinks == "" ? "--" : details.caseLinks }}</a> -->
								<template v-if="caseLinks.length != 0">
									<a v-for="(item, index) in caseLinks" :key="index" :href="item.indexOf('http') ? 'http://' + item : item" target="_blank">{{ item }}</a>
								</template>
								<template v-else>
									<span>无</span>
								</template>
							</a-col>
						</a-row>
					</a-col>
					<a-col :span="4">
						<a-row style="marginTop:5px">
							<a>
								<span @click="getInformation">{{ details.type == 1 ? '查看人才资料' : '查看专家资料' }}</span>
							</a>
						</a-row>

						<a-button :type="!focus ? 'primary' : ''" class="focusBtn" @click="focusBtn">
							{{ !focus ? '关注' : '已关注' }}
						</a-button>
					</a-col>
				</a-row>
				<a-divider />
				<a-row class="details">
					<a-col :span="4" :offset="3" class="chargeStandard" v-if="commentsList.type != 1">
						<P>约见面</P>

						<a-row v-for="item in meets" :key="item.flowId">
							<span>收费标准：</span>
							<span>{{ item.price / 1000 }}元/</span>
							<span>{{ item.unit }}小时</span>
						</a-row>
						<a-row>
							{{ meets.length > 0 ? '' : '暂无收费标准' }}
						</a-row>
					</a-col>
					<a-col :span="7" :offset="3" class="chargeStandard">
						<P>{{ details.type == 1 ? '谈合作' : '请顾问' }}</P>

						<a-row v-for="item in cooperations" :key="item.flowId">
							<span>收费标准：</span>
							<span>{{ item.price / 1000 }}元/</span>
							<span>{{ item.unit }}小时</span>
						</a-row>
						<a-row>
							{{ cooperations.length > 0 ? '' : '暂无收费标准' }}
						</a-row>
					</a-col>

					<a-col :offset="17" class="charge">
						<a-button type="primary" @click="contact">
							马上联系
						</a-button>
					</a-col>
					<a-col :offset="17" class="charge">
						<a-button type="primary" @click="meet(3)" v-if="meets.length > 0">
							约见面
						</a-button>
					</a-col>
					<a-col :offset="17" class="charge">
						<a-button type="primary" @click="meet(2)" v-if="cooperations.length > 0">
							{{ commentsList.type == 1 ? '谈合作' : '请顾问' }}
						</a-button>
					</a-col>
				</a-row>
				<a-divider />
				<a-row class="titleRow">
					<span class="longString"></span>
					用户评论
				</a-row>

				<a-row>
					<a-col :offset="3" :span="15">
						<div class="demo-infinite-container" :infinite-scroll-disabled="busyScroll" :infinite-scroll-distance="10">
							<a-list :data-source="data">
								<a-list-item slot="renderItem" slot-scope="item">
									<a-list-item-meta :description="item.content">
										<a slot="title" :href="item.href">{{ item.userName && item.userName.lenth > 2 ? '*' + item.userName.slice(1) : item.userName && item.userName.lenth == 1 ? '*' : '未命名用户' }}</a>
									</a-list-item-meta>
									<div>{{ $moment(item.addTime).format('YYYY-MM-DD HH:MM:SS') }}</div>
								</a-list-item>
								<div v-if="loading && !busy" class="demo-loading-container">
									<a-spin />
								</div>
							</a-list>
						</div>
					</a-col>
				</a-row>

				<!-- 用于存放并复制当前链接的input -->
				<input type="text" v-model="text" readonly="readonly" id="inputText" ref="inputText" />
			</a-spin>
		</a-row>
		<CustomerService :visible="contactVisible" :closeModal="closeModal" :goType="goType" />
		<ContactModel :meetVisible="meetVisible" :meetCloseModal="meetCloseModal" :infoData="commentsList" :type="type"></ContactModel>
	</div>
</template>

<script>
import CustomerService from '../Home/components/CustomerService.vue';
import ContactModel from './components/ContactModel.vue';
import infiniteScroll from 'vue-infinite-scroll';
import store from '../../store';
export default {
	directives: { infiniteScroll },
	components: {
		CustomerService,
		ContactModel,
	},
	data() {
		return {
			focus: false,
			contactVisible: false,
			goType: 'customerService',
			meetVisible: false,
			data: [],
			commentsList: {},
			details: {},
			caseLinks: [],
			baseImgUrl: process.env.VUE_APP_RESOURCES_HOST,
			type: '',
			loading: false,
			busy: false,
			flowId: 0,
			// 谈合作
			cooperations: [],
			// 约见面
			meets: [],
			text: window.location.href,
			token: '',
			pageLoading: true,
		};
	},

	mounted() {
		// this.getCommentsList();
		let token = store.getters.token;
		// console.log("token",token)
		if (token) {
			this.OnFocus();
			this.token = token;
		} else {
			this.getCommentsList();
		}
	},
	methods: {
		getTalentPool() {
			this.$router.push({
				name: 'TalentPool',
				query: {},
			});
		},
		copy() {
			var tes = this.$refs.inputText;
			tes.select(); // 选择对象
			document.execCommand('Copy');
			this.$message.success('复制成功');
		},
		handleInfiniteOnLoad() {},
		busyScroll() {},
		getInformation() {
			this.$store.dispatch('saveUsertalentInfo', this.details);
			this.$router.push({
				name: 'Information',
				query: { flowId: this.$route.query.flowId },
			});
		},
		// 是否关注
		OnFocus() {
			let userTalentId = this.$route.query.flowId;
			const _this = this;
			this.$http
				.post('/resource/usertalentfollow/queryUserTalent?userTalentId=' + userTalentId)
				.then(res => {
					if (res.rescode == 200) {
						_this.focus = true;
						_this.flowId = res.data.flowId;
						_this.getCommentsList();
					} else {
						_this.getCommentsList();
						_this.focus = false;
						// this.$message.warning(res.msg);
					}
				})
				.catch(error => {
					console.log(error);
				});
		},
		getCommentsList() {
			var flowId = this.$route.query.flowId;
			this.$http
				.get('/resource/usertalent/query?flowId=' + flowId)
				.then(res => {
					if (res.rescode == 200) {
						this.details = res.data;
						if (this.details.caseLinks) {
							this.caseLinks = this.details.caseLinks.split(',').filter(item => item != '');
						}
						this.commentsList = res.data;
						this.cooperations = this.commentsList.userTalentCooperationVoList.filter(item => {
							return item.cooperationMode == 1;
						});
						this.meets = this.commentsList.userTalentCooperationVoList.filter(item => {
							return item.cooperationMode == 2;
						});
						this.data = [...[], ...res.data.orderReviewVoList];
					} else {
						this.$message.warning(res.msg);
					}
					this.pageLoading = false;
				})
				.catch(error => {
					console.log(error);
					this.pageLoading = false;
				});
		},
		// 关注按钮
		focusBtn() {
			let _this = this;
			if (!this.token) {
				sessionStorage.setItem('routerCome', window.location.href);
				this.$modal.warning({
					title: '请登录后操作',
					onOk() {
						_this.$router
							.push({
								name: 'Login',
							})
							.then(r => {});
					},
				});
				return;
			}
			if (this.focus) {
				this.$modal.confirm({
					title: '确定取消关注么?',
					onOk() {
						return new Promise((resolve, reject) => {
							const res = _this.deleteActive(resolve, reject);
							return res;
						});
					},
					onCancel() {},
				});
			} else {
				let params = {
					userCode: this.$store.getters.userInfo.userCode,
					userTalentId: this.$route.query.flowId,
				};
				this.$http
					.post('/resource/usertalentfollow/add', params)
					.then(res => {
						if (res.rescode == 200) {
							this.focus = true;
							this.OnFocus();
						} else {
							this.$message.warning(res.msg);
						}
					})
					.catch(error => {
						console.log(error);
					});
			}
		},
		deleteActive(resolve, reject) {
			// const that = this;

			this.$http
				.post('/resource/usertalentfollow/delete?flowId=' + this.flowId)
				.then(res => {
					if (res.rescode == 200) {
						this.focus = false;
						this.getCommentsList();
					} else {
						this.$message.warning(res.msg);
					}
					resolve();
				})
				.catch(error => {
					console.log(error);
					reject();
				});
		},
		// 马上联系
		contact() {
			this.contactVisible = true;
		},
		closeModal() {
			this.contactVisible = false;
		},
		getType() {},
		// 约见面，请顾问
		meet(type) {
			let _this = this;
			if (!this.token) {
				sessionStorage.setItem('routerCome', window.location.href);
				this.$modal.warning({
					title: '请登录后操作',
					onOk() {
						_this.$router
							.push({
								name: 'Login',
							})
							.then(r => {});
					},
				});
				return;
			}
			this.meetVisible = true;
			this.type = type;
			var flowId = this.$route.query.flowId;
		},
		meetCloseModal() {
			this.meetVisible = false;
		},
		fetchData(callback) {
			request({
				url: fakeDataUrl,
				type: 'json',
				method: 'get',
				contentType: 'application/json',
				success: res => {
					callback(res);
				},
			});
		},
		handleInfiniteOnLoad() {
			const data = this.data;
			if (data.length > 0) {
				this.loading = true;
			} else {
				this.loading = false;
				return;
			}

			if (data.length > 14) {
				this.$message.warning('Infinite List loaded all');
				this.busy = true;
				this.loading = false;
				return;
			}

			this.fetchData(res => {
				this.data = data.concat(res.results);
				this.loading = false;
			});
		},
	},
};
</script>

<style lang="less" scoped>
.share {
	margin: 10px 0;
}
.titleRow {
	// margin-top: 30px;
	font-size: 20px;
	position: relative;
	margin-left: 12.5%;
	padding: 15px 0;
	.longString {
		width: 3px;
		height: 25px;
		background-color: #2d7bf8;
		position: absolute;
		top: 12px;
		left: -10px;
	}
}
.pageContainer {
	#inputText {
		opacity: 0;
		position: fixed;
		bottom: 0;
		left: 200px;
		// display: none;
	}
	span {
		font-size: 14px;
	}
	a {
		font-size: 14px;
	}
	.financialManagement {
		padding: 16px;
		height: 100%;
		background: #fff;
		.choose {
			margin-top: 10px;
		}
		.button {
			margin-top: 3px;
			margin-right: 20px;
			color: #999999;
		}
		.details {
			// margin: 50px 100px 0 140px;
			// padding: 50px 100px 0 140px;
			margin-top: 20px;
			.row-center {
				line-height: 45px;
			}
			.focusBtn {
				margin-top: 70px;
				width: 100px;
				display: block;
				// margin-left: 20px;
			}
			.chargeStandard {
				font-size: 14px;
				margin-bottom: 20px;
				.ant-row {
					margin-top: 10px;
				}
			}
			.charge {
				.ant-btn {
					width: 100px;
					margin-bottom: 20px;
					display: block;
				}
			}
			.commentsList {
				margin-top: 10px;
				height: 200px;
				border: gray 1px solid;
				overflow: auto;
			}
			.demo-infinite-container {
				border: 1px solid #e8e8e8;
				border-radius: 4px;
				overflow: auto;
				padding: 8px 24px;
				height: 200px;
			}
			.demo-loading-container {
				position: absolute;
				bottom: 40px;
				width: 100%;
				text-align: center;
			}
		}
		.talentList {
			margin: 20px 30px;
			height: 120px;
			border-bottom: 1px solid #eee;
			.detailsBtn {
				margin-top: 45px;
			}
		}
	}
}
</style>
